@mixin common-font {
  font-family: "Open Sans";
  font-style: normal;
}

.filter {
  color: white;
  margin-left: 225px;
  margin-top: -23px;
}

.deloitte-logo {
  width: 80px;
  background: transparent;
  margin-left: 20px;
  margin-top: -6px;
}

.deloitte-green-dot {
  margin-left: 1px;
  margin-top: 10px;
}

.separator {
  color: #d0d0ce;
  opacity: 0.5;
  margin-left: 12px;
}

.digiPrint-title {
  width: 68px;
  height: 24px;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  color: #ffffff;
  margin-left: 12px;
  font-size: 14px;
  font-weight: 600;
  font-family: "Open Sans";
  cursor: pointer;
}

@mixin common-nav-links {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  /* identical to box height, or 150% */
  letter-spacing: 0.02em;
  // width: 47px;
  // padding: 0 20px;
}
.nav-link-active {
  @include common-nav-links;
  color: #ffffff;
  text-underline-offset: 12.4px;
  text-decoration: underline;
  text-decoration-color: var(--color-homepage-menu-active);
  text-decoration-thickness: 5px;
}

.nav-highlight {
  // background: #3B3D40;
}

.nav-link-inactive {
  @include common-nav-links;
  color: #d0d0ce;
  // margin-left: 22px;
}

.arrow {
  // margin-left: px;
}

.user-logo {
  height: 33px;
  width: 33px;
  float: right;
  border-radius: 50%;
  z-index: 10;
  border-radius: 50%;
  cursor: pointer;
  position: relative;
  right: 14px;
}
#navigation {
  cursor: pointer;
}
@media screen and (max-width: 1366px) {
  #logo {
    width: 19%;
  }

  #navigation {
    width: 60%;
  }

  #user {
    width: 21%;
  }
  // .arrow {
  //   margin-left: 26px !important;
  // }
}
@media screen and (max-width: 1280px) {
  .sub-sectors-box {
    margin-left: 18px !important;
  }
  .sub-sectors-text-active {
    margin-left: -1px !important;
    width: 100% !important;
    height: fit-content;
  }
  .sub-sectors-text-inactive,.sub-sectors-text-disabled {
    width: 100% !important;
    margin-left: 0px !important;
  }
  .sub-sectormenu-dropdown {
    margin-left: -45px !important;
  }
  .storefront-popup-left-selected {
    width: 40% !important;
  }

  .storefront-popup-left-unselected {
    width: 100% !important;
  }
  .storefront-popup-right {
    width: max-content !important;

  }
  .homescreen-storefront-menu {
    padding-top: 33px !important;
    padding-left: 35px !important;
  }
}

@media screen and (min-width: 1160px) {
  #logo {
    width: 20% !important;
  }

  #navigation {
    width: 61% !important;
  }

  #user {
    width: 19% !important;
  }
  .sub-sectors-box {
    margin-left: 18px !important;
  }
  .sub-sectors-text-active {
    margin-left: -1px !important;
    width: 100% !important;
    margin-bottom: 0px;
    height: fit-content;
  }
  .sub-sectors-text-inactive,.sub-sectors-text-disabled {
    width: 100% !important;
    margin-left: 0px !important;
  }
  .sub-sectormenu-dropdown {
    margin-left: -45px !important;
  }
  .storefront-popup-left-selected {
    width: 40% !important;
  }
  .storefront-popup-left-unselected {
    width: 100% !important;
  }
  .storefront-popup-right {
    width: max-content !important;
  }
  .homescreen-storefront-menu {
    padding-top: 33px !important;
    padding-left: 0px !important;
  }
  // .modal-open .nav-link-inactive .arrow {
  //   margin-left: 69px !important;
  // }
}
@media screen and (max-width: 1280px) {
  #logo {
    width: 20% !important;
  }

  #navigation {
    width: 57% !important;
  }

  #user {
    width: 23% !important;
  }
}


@media screen and (max-width: 1098px) {
  #logo {
    width: 25%;
  }

  #navigation {
    width: 70%;
  }

  #user {
    width: 5%;
  }
}

.sub-sector-section-selected {
  height: 586px;
  background: #fcfcfc;
  border-right: 1px solid #e6e6e6;
  z-index: 1000;
  width: 60%;
  position: absolute;
  top: 23px;
  left: 2%;
  overflow-y:scroll !important;
}

.sub-sector-section-unselected {
  height: 586px;
  background: #fcfcfc;
  border-right: 1px solid #e6e6e6;
  z-index: 1000;
  // width: 99.2%;
  position: absolute;
  top: 23px;
  left: 2%;
  width: 32%;
  overflow-y:scroll !important;
}

.sub-sectors-box {
  margin-left: 65px;
  margin-top: 32px;
}

.sub-sectors-header {
  font-family: "OpenSans-Bold";
  font-weight: 700;
  font-size: 18px;
  line-height: 28px;
  color: #000000;
}

@mixin sub-sector-text-common {
  margin-left: 35px;
  padding: 8px 24px;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  position: relative;
}
.sub-sectors-text-active {
  gap: 8px;
  // width: 288px;
  width: 92%;
  height: fit-content;
  // background: linear-gradient(108.11deg, #007cb0 0%, #00a3e0 84.05%);
  background: #86BC25;
  @include common-font();
  @include sub-sector-text-common();
  color: #ffffff;
  cursor: pointer;
}

.sub-sectors-text-inactive {
  gap: 8px;
  height: 24px;
  @include common-font();
  @include sub-sector-text-common();
  color: #000000;
  cursor: pointer;
}

.sub-sectors-text-disabled {
  gap: 8px;
  height: 24px;
  @include common-font();
  @include sub-sector-text-common();
  color: #000000;
}

.inactive-industry {
  cursor: default;
  color: lightgrey;
}

.border-right {
  border-right: 1px solid #e6e6e6;
}

.sub-sectors-heading {
  margin-left: 65px !important;
  margin-top: 10px;
  font-family: "OpenSans-Bold";
  font-weight: 700;
  font-size: 14px;
  line-height: 24px;
  color: #000000;
  padding-bottom: 7px;
  border-bottom: 1px solid #e6e6e6;
}

.sub-sectors-sub-text-inactive {
  height: 24px;
  @include common-font();
  @include sub-sector-text-common();
  color: #000000;
  margin-left: 65px !important;
  padding: 0;
  cursor: pointer;
}

.sub-sectors-sub-text-inactive:hover {
  color: #00ABAB;
}

.modal-lg {
  --bs-modal-width: 100% !important;
}

.modal-content {
  border: none !important;
}
.modal {
  padding: 0 !important;
}
.modal-open {
  padding-right: 0px !important;
}

.side-arrow {
  float: right;
  padding-top: 8px;
}

.sub-sectormenu-dropdown {
  overflow-y: scroll;
  overflow-x: hidden;
  min-height: 400px;
  max-height: 425px;
}

.sub-sectormenu-dropdown::-webkit-scrollbar {
  width: 6px;
}
.sub-sectormenu-dropdown::-webkit-scrollbar-track {
  border-radius: 5px;
}
.sub-sectormenu-dropdown::-webkit-scrollbar-thumb {
  border-radius: 5px;
  background: rgba(0, 0, 0, 0.3);
}
.modal {
  overflow-y: hidden !important;
}
.homescreen-storefront-menu {
  height: 24px;
  font-family: "OpenSans-Regular";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: #00ABAB;
  padding-top: 33px;
  padding-left: 35px;
  cursor: pointer;
}
.storefront-menu-dot {
  width: 4px;
  height: 4px;
  background: white;
  bottom: 4px;
  display: inline-block;
  position: relative;
  right: 10px;
}
.storefront-popup-left-selected {
  width: 40% !important;
}

.storefront-popup-left-unselected {
  width: 100%;
}
.storefront-popup-right {
  width: max-content !important;
}

.icon-up {
  margin-top: -5px;
}

.home-up {
  margin-top: -1px;
}

.storefront-menu-up {
  background: #3b3d40;
  height: 44px;
  color: #ffffff;
  margin-top: -6px ; 
  padding-top: 6px;

}

.opened-modal-nav-link-active {
  color: #D0D0CE;
  // text-underline-offset: 12.4px;
  text-decoration: none;
  padding-top: 5px;
  // text-decoration-color: var(--color-homepage-menu-active);
  // text-decoration-thickness: 5px;
}
.storefront-menu-openmodal {
  display: inline-block;
  padding-top: 5px;
  padding-bottom: 5px;
  margin: 0 20px;
  font-size: 14px;
  font-weight: 600;
  font-family: 'Open Sans';
}

.navbar-login-user-popup .dropdown-toggle {
  background: black;
  padding-top: 0px;
  height: 38px;
  float: right;
}
.navbar-login-user-popup .btn-secondary {
  --bs-btn-border-color: none !important;
}
.navbar-login-user-popup .dropdown-toggle::after {
  color: black !important;
}
.navbar-login-user-popup .dropdown-toggle:focus {
  box-shadow: none !important;
}
.navbar-login-user-popup .dropdown-menu.show {
  width: 350px;
  height: 130px;
  padding-top: 22px;
  border-radius: 0px !important;
}
.navbar-login-user-popup .navbar-login-user-name {
  margin-bottom: 0px !important;
  text-transform: capitalize;
  font-size: 14px;
  font-style: normal;
  font-weight: normal;
  line-height: 28px;
  color: #000;
  font-family: "OpenSans-Regular";
}
.navbar-login-user-popup .navbar-login-user-email {
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 28px;
  color: #97999b;
  font-size: 11px;
}
.navbar-login-user-popup .user-navbar-logout-icon {
  cursor: pointer;
  height: 22px;
  padding-left: 30px;
}
.navbar-login-user-popup .user-navbar-logout-icon1 {
  font-size: 13px;
  cursor: pointer;
  font-family: "OpenSans-Regular";
}
.navbar-login-user-popup-bottom {
  border-bottom: 1px solid #cfd1d2;
  margin-bottom: 7px !important;
}
.navbar-login-userpopup-image {
  height: 48px;
  width: 48px;
  float: right;
}
.nav-user-menu {
  position: absolute !important;
  top: 37px !important;
}

.sub-sectors-inactive{
    cursor: default;
    color: lightgrey !important;
}

.justify-center{
  display: flex;
  justify-content: center;
  align-items: center;
}

.industry{
  font-size: 14px;
  font-weight: 600;
  font-family: 'Open Sans';
}

.tabName{
  font-weight: bold !important;
  font-size: 15px;
  padding: 5px 0px 5px 0px;
  margin: 0px 20px;
  font-size: 14px;
  font-weight: 600;
  font-family: 'Open Sans';
}

.tabNameHover{
    border-bottom: 1px solid #53565A;
    cursor: pointer;
    padding: 5px 0px 3px 0px;
    margin: 0px 20px;
    font-size: 14px;
    font-weight: 600;
    font-family: 'Open Sans';

}

.tabNameSelected{
  border-bottom: 2px solid white;
  padding: 5px 0px 3px 0px;
  margin: 0px 20px;
  font-size: 14px;
  font-weight: 600;
  font-family: 'Open Sans';
}

.giveFeedback{
  margin-top: -4px;
  height: 43px;
  cursor: pointer;
  visibility: hidden;
}
