.descriptiveCardContainer {
    background: #FFFFFF;
    border-radius: 4px;
    width: auto;
    padding: 40px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-right: 40px;
}

.cardTag {
    background: #DDEFE8 !important;
    border-radius: 4px !important;
    color: #0D8390 !important;
}

.cardTitle {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 32px;
    color: #000000;
}

.cardDescription {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #000000;
}

.linkArrow {
    width: 30.33px;
    height: 30.33px;
    margin-left: 110px;
}

.descriptiveCardContainer:last-child{
    margin-right: 0px!important;
}
