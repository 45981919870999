.digiprintInfoContainer {
    background-color: black;
    padding: 80px;
}


.digiprintTitle {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 600;
    font-size: 40px;
    line-height: 52px;
    color: #FFFFFF;
}

.digiprintDescription {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #BBBCBC;
    margin-top: 24px;
    max-width: 740px;
}

.digiprintInfoItemTitle {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #FFFFFF;
}

.digiprintInfoItemDescription {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 32px;
    color: #FFFFFF;
    margin-top: 24px;
}

.digiprintInfoItemTitleSection{
    padding: 96px 0px 96px 0px;
    display: flex;
    flex-direction: column;
}

.digiprintInfoimage{
    min-width: 476px;
    height: 336px;
    float: right;
}