.digiprint-footer {
  width: 100% !important;
  height: auto;
  left: 0px;
  background: #000000;
}

.footer-logo {
  background: transparent;
  width: 142px;
  height: 27px;
  margin-bottom: 15px;
}
.footer-logo-section {
  padding-top: 50px;
  padding-right: 40px;
  padding-left: 80px;
}
.footer-div1 {
  color: white;
}
.footer-content-section1 {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
  letter-spacing: 0.04em;
  color: #878787;
}
.footer-content-section2 {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #ffffff;
  text-decoration: underline;
  cursor: pointer;
}

.footer-content-section2 .terms-of-use{
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 28px;
  color: #ffffff;
  text-decoration: none;

}
.footer-div2,
.footer-div3 {
  margin-top: 47px !important;
}
.footer-paragraph {
  text-align: justify;
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
  margin-top: 30px;
  color: #797d88;
}

.cookie-setting-btn {
  color: white !important;
  border: none !important;
  padding: 0 !important;
  font-size: 14px !important;
  line-height: 28px !important;
  background: none !important;
}

.cookie-setting-btn:hover {
  background: none !important;
}

.footer_links_horizontal {
  display: flex;
  flex-direction: column;
}
.links_separator {
  color: #d0d0ce;
    opacity: 0.5;
    margin-left: 10px;
    margin-right: 10px;
    margin-top: 2px;
}
.email_address {
  color: #ffffff !important;
  text-decoration: none !important;
}
.footer_email_icon {
  padding-right: 7px;
}