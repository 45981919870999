.container{
    padding: 80px 50px 128px 10px;
    font-family: 'Open Sans' !important;
    background-color: white !important;
    width: 90% !important;
}

.digiprint{
    font-size: 26px;
    padding: 0px 10px;
    font-weight: 600;
    letter-spacing: 0.1em;
    text-transform: uppercase;
    margin-bottom:22px;
    font-family: 'Open Sans' !important;
    color:black
}

.containerHeader{
    padding: 22px 40px 20px 34px;
    background-color: #041E42;
    color: white;
    margin-left: 0 !important;
}

.containerBody{
    background-color: white;
    margin-left: 0 !important;
    z-index: 9;
    color: black;
    width: 1060px;
}

.capabilityContainer{
    padding: 0px 15px;
    font-weight: 500 !important;
    margin: 8px 0px;
    font-size: 13px;
    font-family: "Open Sans";
}

.capabilityName{
    display: inline-block !important;
}

.capabilityNameContainer{
    display: inline-block !important;
    padding: 5px 0px;
}

.valueChain{
    font-weight: 600 !important;
    font-size: 15px;
    background-color: #f4f4f4;
    padding: 12px 15px;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
}

.card{
    border: 1px solid #D0D0CE;
    box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.04);
    border-radius: 8px;
}

.selectionContainer{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    border-radius: 100px;
    border: 1px solid #D0D0CE;
    margin:0px 10px;
    position: absolute;
    bottom: 40px;
    width: 1040px
}

.selectionName{
    font-family: "Open Sans";
    text-align: center;
    color: #BBBCBC;
    font-style: normal;
    font-weight: 600;
    font-size: 11px;
    line-height: 20px;
    display: flex;
    align-items: center;
    letter-spacing: 0.1em;
    padding: 8px 15px;
    text-transform: uppercase;
}