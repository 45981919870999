.ant-collapse-content-box {
    padding: 0px!important;
}

.ant-collapse{
    border-radius: 4px !important;
}

.ant-collapse-header{
    padding: 12px 10px !important;
    //background-color: #0D8390;
    //border-radius: 4px;
}
.ant-collapse-item{
    border: 0px solid !important;
    border-radius: 4px !important;
}
.ant-collapse-item:last-child, .ant-collapse-content{
    border-color: #f4f4f4 !important;
}

.ant-collapse-expand-icon{
    padding: 6px !important;
}

.anticon-holder:hover{
    border: 0px solid #f4f4f4 !important;
    border-radius: 50%;
    padding: 3px;
    background: #D2D0CE;
}

.anticon-holder{
    padding: 3px;
}

.anticon-plus:hover{
    border: 0px solid #6FC2B4 !important;
    border-radius: 50%;
    padding: 3px;
    background: #6FC2B4;
}

.anticon-plus{
    padding: 3px;
}

.anticon-minus:hover{
    border: 0px solid white !important;
    border-radius: 50%;
    padding: 3px;
    background: #0D8390;
}

.anticon-minus{
    padding: 3px;
}

.anticon-up{
    padding: 6px !important;
}

.anticon-up:hover{
    border: 0px solid #f4f4f4 !important;
    border-radius: 50%;
    padding: 6px !important;
    background: #bdbaba;
}

.anticon-down{
    padding: 6px !important;
}

.anticon-down:hover{
    border: 0px solid #f4f4f4 !important;
    border-radius: 50%;
    padding: 6px  !important;
    background: #bdbaba
}

.add-links{
    text-decoration: underline !important;
    color: black !important;
}

.add-links:hover{
    text-decoration: underline !important;
    color: black !important;
    cursor: pointer;
}

.navbar {
    width: 100.95%;
    height: 54px;
    background: #000000;
    font-family: "Open Sans";
    margin-left: 0 !important;
    position: fixed;
    right: 0;
    left: 0;
    z-index: 1051;
    opacity: 1;
    transition: none;
    top: 0;
  }

.ant-notification-notice-close {
    top: 14px !important
}

.ant-notification-notice-message{
    color:white !important;
}


.ant-notification-close-x{
    color: white;
}
.ant-notification-notice .ant-notification-notice-message {    
    font-size: 14px;
    border: none;

}

.ant-notification-notice .ant-notification-notice-close {
    right: 14px;
    justify-content: right;
}

.ant-notification-notice-close:hover{
    background-color: rgb(38, 137, 13) !important;
}

.ant-modal-close:hover{
    border: none !important;
    background-color: white !important;
}

.ant-modal .ant-modal-content {
    padding: 24px 24px !important;
}

.ant-input:placeholder-shown{
    border-radius: 0 !important;
}

.ant-modal .ant-modal-footer{
    margin-top: 30px;
}

.ant-input:placeholder-shown{
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #63666A;
}

.dropdown{
    display: inline-block;
    float: right;
}