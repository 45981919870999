.mainDiv {
    padding: 40px 40px 40px 40px;
    display: flex;
    justify-content: space-between;
    background-color: #f4f4f4;
}

.sticky {
    margin-right: 144px;
    align-self: flex-start;
    position: sticky;
    -webkit-sticky: 'sticky';
    top: 3.5rem;
    height: auto;
}

.notSticky {
    margin-right: 144px;
    align-self: flex-start;
    height: auto;
}

.panelStyle {
    margin-bottom: 16px;
    background-color: white;
    border: none;
    color: black;
}

.activeQuestionStyle {
    padding-left: 13px;
    font-size: 16px;
    font-weight: 500;
    font-family: Open Sans;
    color: #0D8390;
}

.questionStyle {
    padding-left: 13px;
    font-size: 16px;
    font-weight: 500;
    font-family: Open Sans;  
}

.answerStyle {
    padding: 24px;
    font-family: Open Sans;
    font-size: 14px;
}

.answerStyle a {
    color: #0D8390;
    cursor: pointer;
}