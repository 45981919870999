/* @import '@radix-ui/colors/mauve.css'; */
/* reset */
a {
  all: unset;
}
.HoverCardContent {
  border-radius: 6px;
  padding: 20px;
  width: 300px !important;
  background-color: white;
  box-shadow: hsl(206 22% 7% / 35%) 0px 10px 38px -10px, hsl(206 22% 7% / 20%) 0px 10px 20px -15px;
  animation-duration: 400ms;
  animation-timing-function: cubic-bezier(0.16, 1, 0.3, 1);
  will-change: transform, opacity;
}
.HoverCardContent[data-side='top'] {
  animation-name: slideDownAndFade;
}
.HoverCardContent[data-side='right'] {
  animation-name: slideLeftAndFade;
}
.HoverCardContent[data-side='bottom'] {
  animation-name: slideUpAndFade;
}
.HoverCardContent[data-side='left'] {
  animation-name: slideRightAndFade;
}
.HoverCardArrow {
  fill: white;
}
.ImageTrigger {
  cursor: pointer;
  border-radius: 100%;
  display: inline-block;
}
.ImageTrigger:focus {
  box-shadow: 0 0 0 2px white;
}
.Image {
  display: block;
  border-radius: 100%;
}
.Image.normal {
  width: 45px;
  height: 45px;
}
.Image.large {
  width: 60px;
  height: 60px;
}
.Text {
  margin: 0;
  color: var(--mauve12);
  font-size: 15px;
  line-height: 1.5;
}
.Text.faded {
  color: var(--mauve10);
}
.Text.bold {
  font-weight: 500;
}
.capability-label{
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 36px;
    color: #000000;
    padding: 10px 15px 0px 15px;
}


.capability-label-container{
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 24px 24px 16px 0px;
    gap: 10px;
}

.capability-tooltip-content{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 24px 39px 26px;
    gap: 10px;
}

.capability-suggestion-content{
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 24px 39px 26px;
  gap: 10px;
  overflow-y: auto;
  overflow-x: hidden;
}

.capability-befinits-section{
    /* display: flex;
    flex-direction: column;
    align-items: flex-start; */
    padding: 0px;
    gap: 8px;
    margin-bottom: 10px;
}
.capability-benifits-label{
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 16px;
    color: #000000;
    margin-bottom: 8px;
}
.capability-benifits-points{
    display: flex;
    flex-direction: row;
    /* align-items: center; */
    padding: 0px;
    gap: 8px;
}
.tick{
    color: #26890D;
}
.benefit-points-text{
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    color: #53565A;
}
.capability-description-section{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0px;
    gap: 8px;
}
.capability-description-label{
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 16px;
    color: #000000;
}
.capability-description{
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #53565A;
}