.capabilityBreadcrumb {
    display: flex;
    flex-direction: row;
}

.capabilityHeaderContainer {
    padding: 30px 30px 30px 0px;
    border-radius: 0px;
    border: 0px;
    border-bottom: 1px solid #E9E9E9;
}

.capabilityBreadcrumbItem {
    display: flex;
    font-family: 'Open Sans';
    font-size: 14px;
    align-items: left;
    color: #75787B;
    max-width: 220px;
    margin-left: 25px;
}

.capabilityBreadcrumbItem::after {
    content: "/";
    padding-left: 10px;
}

.valuechainTag {
    background-color: #041E42 !important;
    color: white !important;
    margin-top: 60px;
}

.capabilityBreadcrumbItemActive {
    font-family: 'Open Sans';
    font-size: 14px;
    align-items: left;
    color: #0D8390;
    text-decoration: underline;
    cursor: pointer;
    margin-left: 20px;
}





.CapabilityNameHeader {
    margin-top: 12px;
}

.capabilityName {
    font-weight: 600;
    line-height: 56px;
    font-size: 40px;
    font-family: 'Open Sans Semibold';
    align-items: left;
}

.viewerDetails {
    display: flex;
    flex-direction: row;
}

.contact {
    margin-top: 40px;
    margin-left: 40px;
}

.digiprintCount {
    margin-top: 12px;
    display: flex;
}

.viewCountHeading {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 16px;
    color: #000000;
}

.digiprintViewCount {
    width: fit-content;
    flex-direction: column;
}

.lastUpdated {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 16px;
    flex-direction: column;
    margin-top: 6px;
    color: #75787B;
}

.stickyButton {
    position: fixed;
    z-index: 100;
    right: 0px;
    margin-bottom: 10rem;
  }