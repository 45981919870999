.usefulLinksContainer {
    height: 634.33px;
    background: #F4F4F4;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 80px;
}

.descriptiveCards {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    margin-top: 64px;
}

.usefulLinksContainerTitle {
    font-family: 'Open Sans Semibold';
    font-style: normal;
    font-size: 40px;
    line-height: 52px;
}

.usefulLinksContainerDescription {
    margin-top: 24px;
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #53565A;
    max-width: 740px;
}