.mainDiv {
    display: flex;
    justify-content: space-between;
    //grid-auto-rows: minmax(50px, auto);
    width: '100%';
}

.industryDiv {
    cursor: pointer;
    width: max-content;
    height: 38px;
    font-size: 14px;
    font-weight: bold;
    font-family: Open Sans;
    border-bottom: 1px solid transparent;
}

// .industryDiv:hover {
//     border-bottom: 3px solid #0D8390;
// }

.industryDivActive {
    cursor: pointer;
    width: max-content;
    height: 38px;
    font-size: 14px;
    font-weight: bold;
    font-family: Open Sans;
    border-bottom: 3px solid #0D8390;
}