.cardContainer{
    padding: 24px;
    border: 1px solid #D0D0CE;
    border-radius: 8px;
    width: 30.5%;
    margin-right: 24px;
    margin-bottom:24px;
    cursor: pointer;
}

.type{
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    color: #63666A;
    margin-bottom: 8px;

}

.title{
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
}

.wired{
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #0D8390;
}