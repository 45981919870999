.tag {
    display: inline-block;
    height: 24px;

    margin-inline-end: 4px;
    margin-block-end: 4px;
    border-radius: 4px;     
    padding: 0;

    background: var(--color-teal-bg);
    color: var(--color-teal);
    
    font-size: 12px;
    line-height: 24px;
    white-space: nowrap;
    font-family: "Open Sans";
}

.content {
    padding: 4px 8px;
}

.closeable .content {
    padding-inline-end: 4px;
}

.close {
    display: inline-block;
    height: 24px;
    width: 24px;

    margin: 0;
    padding: 7px; // width: 24 = 7 (left pad) + 10px + 7 (right pad)
    border: none;
    border-radius: 4px;

    background: transparent;
    color: var(--color-teal);
    font-size: 10px;
    line-height: 0;

    cursor: pointer;
    transition: all 0.2s,
}

.tag:hover .close, .close:hover {
    background: var(--color-teal-hover);
}