.capabilities-details-back {
  background: #f3f3f3;
  //padding: 1px;
}

.capabilities-header {
  // width: 100%;
  // margin-left: 4%;
  margin-top: 40px;
}

.capabilityDetailsHeader{
  margin: 30px;
}

.capabilities-card {
  border: none;
  background: #ffffff;
  box-shadow: 0px 4px 12px rgba(0, 92, 92, 0.08);
  padding: 30px;
  margin-bottom: 15px;
}

.back-link-arrow {
  transform: rotate(180deg);
  margin-right: 10px;
}

.back-text {
  font-family: var(--font-family);
  font-style: var(--font-style);
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
  color: #00abab;
  font-family: "OpenSans-Bold";

}

.capability-detail-icon {
  margin-left: 10px;
  margin-top: -5px;
  background: linear-gradient(
    108.74deg,
    rgba(221, 239, 232, 0.6) 0%,
    rgba(221, 239, 232, 0.288) 100%
  );
  box-shadow: 0px 0px 50px -25px rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(50px);
  border-radius: 24px;
}

.capability-title {
  font-family: var(--font-family);
  font-style: var(--font-style);
  font-weight: 700;
  font-size: 24px;
  line-height: 36px;
  margin-left: 10px;
  color: #000000;
  font-family: "Open Sans";
}

.details-area {
  margin-top: 20px;
  padding: 0px 20px;
}

@mixin details-header {
  font-family: var(--font-family);
  font-style: var(--font-style);
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: #97999b;
  font-family: "Open Sans";
}
.details-titles {
  @include details-header();
  font-family: "Open Sans";
}

.capability-properties-header {
  @include details-header();
  margin-top: 20px;
  margin-left: 5px;
}
.details-text {
  margin-top: -10px;
  text-align: justify;
  font-family: "Open Sans";
}

.capability-properties {
  font-family: var(--font-family);
  font-style: var(--font-style);
  font-weight: 700;
  font-size: 18px;
  line-height: 28px;
  color: #000000;
  margin-left: 5px;
  font-family: "Open Sans";
}

.capability-details-underline {
  margin: 0.7rem 0;
  color: #b1a9a9;
}

.redirect {
  cursor: pointer;
}

@mixin capability-tabs-common {
  font-family: var(--font-family);
  font-style: var(--font-style);
  font-weight: 700;
  font-size: 18px;
  line-height: 180%;
  cursor: pointer;
  font-family: "Open Sans";
}

.capability-tab-active {
  @include capability-tabs-common();
  color: #000000;
  text-underline-offset: 14.4px;
  text-decoration: underline;
  text-decoration-color: #86bc25;
  text-decoration-thickness: 3px;
  z-index: 1;
  font-family: "OpenSans-Bold";
}

.capability-tab-inactive {
  @include capability-tabs-common();
  color: #adaeb1;
  font-family: "OpenSans-Bold";
}

.capability-tab-underline {
  margin-top: 6.9px;
}

.deloitte-asset-icons {
  //margin-left: -7px;
  margin-top: -4px;
}

.market-ready {
  font-family: var(--font-family);
  font-style: var(--font-style);
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
  color: #43b02a;
  font-family: "Open Sans";
}

.da-title {
  font-family: var(--font-family);
  font-style: var(--font-style);
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #000000;
  margin: 10px 0px;
  font-family: "Open Sans";
}

.bullets {
  font-family: var(--font-family);
  font-style: var(--font-style);
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
  color: #00abab;
  font-family: "Open Sans";
}

.links {
  text-decoration: none;
  color: #00abab;
}

.links:hover {
  color: #00abab;
}

.contact-image {
  width: 65px;
}

.contact-details {
  width: calc(100% - 65px);
}

.contact-name {
  font-family: 'Open Sans';
  font-style: var(--font-style);
  font-weight: 700;
  font-size: 14px;
  line-height: 24px;
  color: #000000;
  margin-left: 24px;
}

.contact-designation {
  font-style: var(--font-style);
  font-weight: 400;
  font-size: 12px;
  line-height: 24px;
  color: #75787b;
  font-family: 'Open Sans';
  margin-left: 24px;
}

.contact-email {
  font-family: 'Open Sans';
  font-style: var(--font-style);
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: #75787B;
  margin-left: 24px;
}

.ecopartner-title {
  font-family: var(--font-family);
  font-style: var(--font-style);
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: #000000;
  margin-left: 12px;
  font-family: "OpenSans-Regular";
}

.tab-number-active {
  color: #75787B;
}

.enabler-title{
  margin-left: 10px;
  font-size: 14px;
}

.ecosystem-icon:hover {
  color: inherit;
}

.market-ready,.campaign-title{
  margin-left: 0.5em;
}
.campaign-wrapper{
  margin-bottom:1em ;
}
.usefull-link-wrapper{
  margin-left: 2em;
  p{
    text-indent: 0.5em;
    list-style-type: disc;
    display: list-item;
    margin-left: 0.5em;
    color: #00abab;

    a{
      color: #00abab;
    }
  }

  p:after{
    content: url('../../assets/images/homePage/Learnmore1.png');
    margin-left: 1em;
  }
}

.capability-properties-content{
  margin-left: 8px;
}

.useful-link-custom-cls, .useful-link-custom-cls:hover{
  font-family: "OpenSans-Regular";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
  color: #00ABAB;
  cursor: pointer;
}

.d-row-justify-between{
  display: flex;
  justify-content: space-between;
  flex-direction: row;
}

.asset-mai-link-custom-cls,.asset-mai-link-custom-cls:hover{
  font-family: "OpenSans-Regular";
  font-style: normal;
  font-weight: 800;
  font-size: 16px;
  line-height: 24px;
  color: #00ABAB;
  cursor: pointer;
  margin-top: 8px;
  text-decoration: none;
}

.d-flex-row-center{
  display: flex;
    flex-direction: row;
    align-items: center;
}

.discard-white-spaces {
  white-space: pre-line;
}

.tabs-spacing-top-bottom {
  padding: 10px 0px;
}

.contactsection-image{
 height: 72px;
 width: 72px;
 border-radius: 74px;
 margin-right: 20px;
}

.briefCapabilityInfoStyle {
  margin-right: 24px;
}

.briefCapabilityInfoStyle:hover {
  cursor: pointer;
}