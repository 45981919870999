.card-layout {
  background-color: #ffffff;
  padding: 40px 55px;
  cursor: pointer;
  margin-top: 20px;
  border: none;
  border-radius: 0;
}
.card-title {
  font-family: "OpenSans-Bold";
  font-style: normal;
  font-weight: 600;
  font-size: 19px;
  line-height: 25px;
}

.card-text {
  font-family: "OpenSans-Regular";
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 25px;
  color: #53565a;
  text-align: justify;
}

.link-text {
  font-family: "OpenSans-Regular";
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 25px;
  color: var(--color-links);  
}

.link-arrow {
  width: 18px;
  margin-left: 5px;
}

.card-logo-css {
  width: 40px;
  height: 40px;
  background: linear-gradient(
    108.74deg,
    rgba(160, 220, 255, 0.384) 0%,
    rgba(160, 220, 255, 0.1152) 100%
  );
  box-shadow: 0px 0px 50px -25px rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(100px);
  border-radius: 24px;
  margin-bottom: 10px;
}

.card-comp-footer {
  font-family: "OpenSans-Regular";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: #97999b;
  margin-top: 40px;
}

.inactive-card {
  opacity: 0.5;
}

.inactive-card:hover {
  cursor: not-allowed !important;
}