.readyTogetStartedContainer {
    background-color: #0D8390;
    padding: 80px;
    display: flex;
    flex-direction: row;
}

.titleSectionContainer {
    margin-right: 40px;
    max-width: 604px;
    display: flex;
    flex-direction: column;
}

.readyTogetStartedGif {
    max-width: 604px;
    height: 392.6px;
}

.readyTogetStartedTitle {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 600;
    font-size: 40px;
    line-height: 52px;
    color: #FFFFFF;
}

.readyTogetStartedDescription {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #FFFFFF;
}

.ReadyToGetStartedStepsContainer {
    display: flex;
    flex-direction: column;
    border-bottom: 1px solid rgba(255, 255, 255, 0.32);
}

.stepsContainer {
    display: flex;
    flex-direction: row;
    border-Top: 1px solid rgba(255, 255, 255, 0.32);
    padding: 40px;
}

.pointNumber {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 600;
    font-size: 32px;
    line-height: 40px;
    color: #FFFFFF;
}

.stepTitle {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    color: #FFFFFF;
}

.stepDescription {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #FFFFFF;
}

video {
    clip-path: inset(10px 10px) !important;
}