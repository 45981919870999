.container{
    background-color: #f4f4f4 !important;
    height: 2000px;
}
.mydigiprint{
    padding: 165px 40px 64px 40px;
    background-color: black;
    color: white;
    font-size: 40px;
    font-weight: 600;
    line-height: 52px;
}

.allCardContainer{
    padding: 0px 8px;
}

.cardContainer{
    margin: 8px 9px !important;
    cursor: pointer;
    width:32% !important;
}

.cardBody{
    padding: 40px;
    height: 100%;
}

.cardTitle{
    font-family: 'Open Sans';
    font-weight:700;
    font-size: 24px;
    line-height:32px;
    padding-bottom:26px;
}

.cardRightSection{
    padding: 24px !important;
    background-color: white;
}

.sectorName{
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
}

.industryName{
    color: #75787B;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
}