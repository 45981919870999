@mixin common-font {
  font-family: "Open Sans";
  font-style: normal;
}
.about-storefront-section {
  background: linear-gradient(
      0deg,
      rgba(255, 255, 255, 0.5),
      rgba(255, 255, 255, 0.5)
    ),
    #ddefe8;
  border-right: 1px solid #ebebeb;
  //height: 642px;
  display: block;
}

.digiprint-contactsection {
  //height: 642px;
  display: block;
  background: linear-gradient(
      0deg,
      rgba(255, 255, 255, 0.5),
      rgba(255, 255, 255, 0.5)
    ),
    #ddefe8;
  border-right: 1px solid #ebebeb;
}

.about-contact-subsection {
  background: linear-gradient(108.11deg, #f5f5f5 0%, #fefefe 84.05%), #f5f5f5;
  height: 642px;
  overflow-x: hidden;
  overflow-y: scroll;
  width: 57.77%;
  padding-left: 70px;
  padding-top: 120px;
}

.about-storefront-subsection::-webkit-scrollbar {
  width: 6px;
  height: 10px;
}

.about-storefront-subsection::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px transparent;
  box-shadow: inset 0 0 6px transparent;
  border-radius: 5px;
}

.about-storefront-subsection::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color: #e6e6e6;
}

.about-storefront-header {
  @include common-font();
  margin-left: 270px;
  letter-spacing: 0.02em;
  color: black;
  margin-top: 64px;
  font-weight: 700;
  font-size: 20px;
  line-height: 32px;
}
.about-contact-header {
  font-family: "OpenSans-Bold";
  margin-left: 270px;
  letter-spacing: 0.02em;
  color: #000000;
  margin-top: 64px;
  font-weight: 700;
  font-size: 20px;
  line-height: 32px;
}
.about-storefront-subheader {
  @include common-font();
  margin-left: 100px;
  letter-spacing: 0.02em;
  color: black;
  margin-top: 64px;
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
}

@mixin about-storefront-button-common {
  @include common-font();
  margin-left: 270px;
  padding: 8px 20px;
  gap: 8px;
  width: 288px;
  height: 40px;
  border: none;
  margin-top: 25px;
}

@mixin about-contact-button-common {
  @include common-font();
  margin-left: 270px;
  padding: 8px 20px;
  gap: 8px;
  width: 328px;
  height: 40px;
  border: none;
  margin-top: 25px;
}
@mixin about-storefront-button-text-common {
  font-family: "OpenSans-Regular";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: 0.02em;
  text-align: left;
}

@mixin about-contact-button-text-common {
  font-family: "OpenSans-Regular";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: 0.02em;
  text-align: left;
}

.about-storefront-button-active {
  @include about-storefront-button-common();
  @include about-storefront-button-text-common();
  background: #86bc25;
  color: #ffffff;
}
.about-contact-button-active {
  @include about-contact-button-common();
  @include about-contact-button-text-common();
  background: #86bc25;
  color: #ffffff;
  height: auto !important;
}
.about-storefront-button-inactive {
  @include about-storefront-button-common();
  @include about-storefront-button-text-common();
  border: 1px solid #bbbcbc;
  color: #000000;
  background: #eef7f4;
}

.about-contact-button-inactive {
  @include about-contact-button-common();
  @include about-contact-button-text-common();
  border: 1px solid #bbbcbc;
  color: #000000;
  background: linear-gradient(
      0deg,
      rgba(255, 255, 255, 0.5),
      rgba(255, 255, 255, 0.5)
    ),
    #ddefe8;
  height: auto !important;
}

.about-storefront-description {
  @include common-font();
  margin-left: 100px;
  margin-top: 10px;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: #53565a;
  letter-spacing: 0.03em;
  text-align: justify;
  text-justify: inter-word;
  width: 80%;
}

.about-storefront-image {
  margin-bottom: 10px;
}

/* Style the header */
header {
  background-color: #666;
  padding: 30px;
  text-align: center;
  font-size: 20px;
  color: white;
  height: 30px;
}

header h2 {
  position: relative;
  bottom: 43px;
}
/* Create two columns/boxes that floats next to each other */
nav {
  float: left;
  width: 30%;
  height: auto;
  background: #ccc;
  padding: 20px;
}

/* Style the list inside the menu */
nav ul {
  list-style-type: none;
  padding: 0;
}

article {
  float: left;
  padding: 20px;
  width: 70%;
  background-color: #f1f1f1;
  height: auto; /* only for demonstration, should be removed */
}

/* Clear floats after the columns */
section::after {
  content: "";
  display: table;
  clear: both;
}

/* Style the footer */

@media (max-width: 600px) {
  nav,
  article {
    width: 100%;
    height: auto;
  }
}

.download-btn-new {
  position: relative;
  float: right;
  bottom: 70px;
}

.w3-quarter {
  width: 33% !important;
}
.w3-col.l3 {
  width: 33% !important;
}

/* Style for home screen */
.home-screen {
  width: 100%;
}
.background-image-layout {
  height: 832px;
}

.homepageBanner {
  position: absolute;
  width: 1280px;
  height: 832px;
  left: 0px;
  top: 0px;
}
.home-screen-inner-content {
  padding-top: 105px;
  padding-left: 100px;
  padding-right: 90px;
}
.Digiprint-homepage-image {
  height: 584px;
}
.homepage-title{
    max-width: 994px;
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 600;
    font-size: 56px;
    line-height: 72px;
    color: #ffffff;
}
.homepage-left-content p span {
  color: #9dd4cf;
}
.homepage-left-content1 {
  width: 400px;
  height: auto;
  font-family: "OpenSans-Regular";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  /* or 171% */
  margin-bottom: 28px;
  color: #fafafa;
  text-align: justify;
}

.homepage-left-btn {
  font-family: "OpenSans-Regular";
  flex-direction: row;
  align-items: flex-start;
  padding: 9px 32px;
  gap: 8px;
  width: 165px;
  height: 40px;
  background: #00abab;
  border: none;
  text-decoration: none;
  color: #ffffff !important;
}
.homepage-left-btn:hover {
  color: #ffffff !important;
}
// @media screen and (min-width: 850px) {
//   .homepage-left-content-parent {
//     position: absolute !important;
//     left: -90px !important;
//   }
// }

// @media screen and (min-width: 1000px) {
//   .homepage-left-content-parent {
//     position: absolute;
//     left: -97px;
//   }
// }

// @media screen and (min-width: 1160px) {
//   .homepage-left-content-parent {
//     position: absolute !important;
//     left: -25px !important;
//   }
// }

// @media screen and (min-width: 1280px) {
//   .homepage-left-content-parent {
//     position: absolute !important;
//     left: -10px !important;
//   }
// }

// @media screen and (min-width: 1420px) {
//   .homepage-left-content-parent {
//     position: absolute !important;
//     left: 24px !important;
//   }
// }

// @media screen and (min-width: 1600px) {
//   .homepage-left-content-parent {
//     position: absolute !important;
//     left: 48px !important;
//   }
// }

// @media screen and (min-width: 1700px) {
//   .homepage-left-content-parent {
//     position: absolute !important;
//     left: 80px !important;
//   }
// }

// @media screen and (min-width: 1920px) {
//   .homepage-left-content-parent {
//     position: absolute !important;
//     left: 150px !important;
//   }

// }
/* Style for useful link screen */
.usefull-section {
  width: 100% !important;

  background: #ffffff;
  text-align: center;
  margin-top: 100px;
  padding-left: 110px;
  padding-right: 110px;
  margin-bottom: 70px;
  //display: none;
}
.usefull-section1 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 32px 32px 24px;
  gap: 16px;

  // width: 270px;
  height: 280px;

  background: #00abab;

  /* Inside auto layout */

  flex: none;
  order: 0;
  flex-grow: 0;
  position: relative;
}

.usefull-section-img1 {
  background-repeat: no-repeat;
  background-size: cover;
}
.usefull-section-img2 {
  background-repeat: no-repeat;
  background-size: cover;
}
.usefull-section-img3 {
  background-repeat: no-repeat;
  background-size: cover;
}
.usefull-section-img4 {
  background-repeat: no-repeat;
  background-size: cover;
}
.usefull-section2,
.usefull-section3,
.usefull-section4 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 32px 32px 24px;
  gap: 16px;

  // width: 270px;
  height: 280px;

  background: #f5f5f5;

  /* Inside auto layout */

  flex: none;

  flex-grow: 0;
  position: relative;
}

.usefull-section-header {
  height: 32px;
  font-family: "OpenSans-Bold";
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 32px;
  letter-spacing: 0.02em;
  position: relative;
  bottom: 30px;
}
.usefull-inner-text1 {
  font-family: "OpenSans-Regular";
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 24px;
  color: #ffffff;
  text-align: initial;
}
.usefull-inner-text2 {
  height: 135px;
  font-family: "OpenSans-Regular";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: #fafafa;
  flex: none;
  text-align: initial;
}
.usefull-section-card-truncate {
  height: 135px;
}
.usefull-inner-text3,
.usefull-inner-text5 {
  text-align: initial;
  font-family: "OpenSans-Regular";
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 24px;
  /* identical to box height, or 133% */

  /* Inside auto layout */

  flex: none;
  order: 0;
  align-self: stretch;
  flex-grow: 0;
}
.usefull-inner-text4,
.usefull-inner-text6 {
  font-family: "OpenSans-Regular";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  /* or 171% */

  /* Cool Gray 11 #53565A */

  color: #53565a;

  /* Inside auto layout */

  flex: none;

  flex-grow: 0;
  text-align: initial;
}
.usefull-inner-text8 {
  font-family: "OpenSans-Regular";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  /* or 171% */
  position: relative;
  top: 50px;
  /* Cool Gray 11 #53565A */

  color: #53565a;

  /* Inside auto layout */

  flex: none;

  flex-grow: 0;
  text-align: initial;
}

// .useful-learnmore-top {
//   position: relative;
// }
.useful-learnmore1 {
  text-decoration: none;
  font-family: "OpenSans-Bold";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
  color: #ffffff;
  flex: none;
  order: 0;
  flex-grow: 0;
  cursor: pointer;
}
.useful-learnmore1:hover {
  color: #ffffff;
}
.banner-link-arrow {
  padding-left: 5px;
}
.useful-learnmore2 {
  text-decoration: none;
  font-family: "OpenSans-Bold";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
  /* identical to box height, or 171% */

  /* Accessible Blue #007CB0 */

  color: #00abab;

  /* Inside auto layout */

  flex: none;
  order: 0;
  flex-grow: 0;
  cursor: pointer;
}
.useful-learnmore2:hover {
  color: #00abab;
}
.useful-learnmore-top {
  position: absolute;
  bottom: 15px;
  font-family: "OpenSans-Bold";
}

.contactsection-first {
  width: 91px;
  height: 32px;

  font-family: "OpenSans-Regular";
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 32px;
  /* identical to box height, or 160% */

  letter-spacing: 0.02em;
}
.contactsection-first1 {
  width: 184px;
  height: 140px;

  font-family: "OpenSans-Regular";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 28px;
  /* or 200% */

  /* Cool Gray 11 #53565A */

  color: #53565a;
}

.frequently-question-section {
  width: 100% !important;
  height: 675px;
  left: 0px;
  top: 800px;
  background: #f5f5f5;
  position: relative;
  width: 40px;
  height: 40px;
  left: 25px;
  top: 141px;
}

.contactsection-first2 {
  width: 40px;
  height: 40px;
}
.digiprint-contact-respon {
  padding-top: 11px;
}
.contactsection-third {
  width: 40px;
  height: 40px;
}
.contactsection-first3 {
  width: auto;
  font-family: "OpenSans-Regular";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
}
.contactsection-first4 {
  font-family: "OpenSans-Regular";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: #53565a;
}
.contactsection-first5 {
  font-family: "OpenSans-Regular";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: #53565a;
}
.contactsection-first6 {
  font-family: "OpenSans-Regular";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  text-transform: lowercase !important;
  color: #53565a;
}
.contactsection-first7 {
  font-family: "OpenSans-Regular";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
}
.contactsection-first8 {
  font-family: "OpenSans-Regular";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: #53565a;
}
.contactsection-first9 {
  font-family: "OpenSans-Regular";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: #53565a;
}
.contactsection-first10 {
  font-family: "OpenSans-Regular";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: #53565a;
}

.industry-section {
  //   height: 720px;
  background: #141414;
}

.industry-header {
  text-align: center;
  margin-top: 50px;
  font-family: "OpenSans-Bold";
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 32px;
  letter-spacing: 0.02em;
  color: #ffffff;
}

.industries {
  // margin-left: 390px;
  margin-bottom: 50px;
  width: 60%;
}

.box {
  // width: 350px;
  // height: 250px;
  border: 1px solid rgba(255, 255, 255, 0.2);
  margin-top: 30px;
  padding-bottom: 35px;
  padding-top: 20px;
}

.industry-title {
  width: 218px;
  height: 48px;
  @include common-font();
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  display: flex;
  align-items: center;
  letter-spacing: 0.02em;
  color: #ffffff;
}

.industry-learn-more {
  @include common-font();
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
  color: #9dd4cf;
  font-family: "OpenSans-Bold";
}

.industry-arrow {
  margin-left: 4px;
}

// @media screen and (min-width: 1600px) {
//   .homepage-left-content-parent {
//     position: absolute !important;
//     left: 48px !important;
//   }
// }

.faq-section {
  background: #141414;
  border-bottom: 1px solid #e6e6e6;
}

.faq-section-rightside-image {
  margin: 0;
  padding: 0;
}

.faq-header {
  font-family: "OpenSans-Bold";
  // margin-left: 240px;
  margin-top: 90px;
  font-weight: 700;
  font-size: 20px;
  line-height: 32px;
  color: #ffffff;
  /* identical to box height, or 160% */

  letter-spacing: 0.02em;
}

.faq-line {
  color: #e6e6e6;
  opacity: 0.3 !important;
}

.faq-arrow {
  position: relative;
  top: -45px;
  left: -11px;
}

.faq-title {
  // margin-left: 240px;
  margin-top: 20px;
  font-family: "OpenSans-Regular";
  font-weight: 600;
  font-size: 18px;
  line-height: 24px;
  color: #ffffff !important;
  cursor: pointer;
}

.faq-description {
  @include common-font();
  margin: 20px 0px 20px 0px;
  // width: 780px;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: #d0d0ce;
  text-align: justify;
  text-justify: inter-word;
}

@media screen and (max-width: 1680px) {
  .about-storefront-header,
  .about-storefront-button-active,
  .about-storefront-button-inactive,
  .about-contact-header,
  .about-contact-button-active,
  .about-contact-button-inactive {
    margin-left: 160px;
  }

  .about-storefront-subheader,
  .about-storefront-description {
    margin-left: 53px;
  }

  .industries {
    margin-left: 270px;
    // margin-top: -50px;
    width: 72%;
  }
}

@media screen and (max-width: 1366px) {
  .about-storefront-header,
  .about-storefront-button-active,
  .about-storefront-button-inactive,
  .about-contact-header,
  .about-contact-button-active,
  .about-contact-button-inactive {
    margin-left: 110px;
  }

  .about-storefront-subheader,
  .about-storefront-description {
    margin-left: 55px;
  }
  .about-contact-subheader {
    margin-left: -4px;
  }

  .industries {
    margin-left: 120px;
    // margin-top: -50px;
    width: 84%;
  }
}

@media screen and (max-width: 1280px) {
  .industries {
    margin-left: 60px;
    // margin-top: -50px;
    width: 90%;
  }
}

.select-industry-section {
  cursor: pointer;
}
.select-industry-section:hover {
  background: linear-gradient(
    108.74deg,
    rgba(255, 255, 255, 0.24) 0%,
    rgba(255, 255, 255, 0.06) 100%
  );
  box-shadow: 0px 0px 50px -25px rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(100px);
  border-radius: 2px;
}
.select-industry-section-inactive {
  opacity: 0.5;
  cursor: default;
}
.select-industry-section-inactive:hover {
  background: transparent;
  cursor: not-allowed;
}

.select-industry-sector {
  width: 93%;
  height: 357px;
  background: linear-gradient(108.11deg, #007cb0 0%, #00a3e0 84.05%), #ffffff;
  margin-top: 31px;
}

.select-sector-menubar {
  padding: 19px 28px;
  gap: 4px;
  width: 92.5%;
  height: 63px;
  background: #ffffff;
  box-shadow: 0px 4px 12px rgba(0, 52, 114, 0.08);
  margin-top: 20px;
}
@mixin select-sector-menu-common {
  font-weight: 600;
  font-size: 13px;
  line-height: 20px;
  margin-left: 10px;
  position: relative;
  top: 2px;
}

.select-sector-menu {
  @include common-font();
  @include select-sector-menu-common();
  color: #000000;
  cursor: pointer;
}

.select-sector-menu-grey {
  @include common-font();
  @include select-sector-menu-common();
  color: rgb(178, 173, 173);
}

@mixin select-sector-centerline-common {
  margin-left: 15px;
  width: 34px;
  height: 0px;
  color: white;
  position: relative;
  bottom: 6px;
  margin-right: 15px;
}

.select-sector-centerline {
  @include select-sector-centerline-common();
  border-bottom: 1px solid rgb(178, 173, 173) !important;
}

.select-sector-centerline-blue {
  @include select-sector-centerline-common();
  border-bottom: 1px solid #007cb0 !important;
  margin-left: 5px;
}

.select-sector-menu2 {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 16px;
  gap: 8px;

  width: 145px;
  height: 56px;

  background: #ffffff;

  /* Inside auto layout */

  flex: none;
  order: 0;
  flex-grow: 0;
  margin-left: -19px;
}
.select-sector-menu6 {
  width: 24px;
  height: 24px;

  /* Black #000000 */

  background: #878787;
  border-radius: 12px;

  /* Inside auto layout */

  flex: none;
  order: 0;
  flex-grow: 0;
}
.select-sector-menu7 {
  position: absolute;
  height: 20px;
  left: 208px;
  /* right: 160px; */
  top: calc(50% - 11px);
  font-family: "OpenSans-Regular";
  font-style: normal;
  font-weight: 700;
  font-size: 13px;
  line-height: 20px;
  text-align: center;
  color: #ffffff;
}
.select-sector-menu8 {
  position: absolute;
  left: 233px;
  top: 21px;
  width: 109px;
  height: 20px;

  font-family: "OpenSans-Regular";
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: 20px;
  /* identical to box height, or 154% */

  text-align: center;

  /* Black #000000 */

  color: #878787;

  /* Inside auto layout */

  flex: none;
  order: 1;
  flex-grow: 0;
}
.sector-level-card {
  width: 94%;
  margin-left: 3%;
  // margin-top: 20px;
  padding: 19px 16px;
}
.home-page-inner-menu-bar {
  background: #ffffff;
  padding-top: 60px;
  position: sticky;
  top: 0px;
  padding-bottom: 10px;
  z-index: 15;
}

.select-industry-sector-back {
  background: #f6f6f6;
  height: auto;
  padding: 1px;
}
.flex-container {
  display: flex;
}
.flex-container .home-page-inner-menubar-text {
  margin-left: 70px;
}
@mixin sector-menu-circle {
  padding: 2.5px 8.96px;
  border-radius: 15px;
  color: white;
  position: relative;
  top: 1.8px;
}
.sector-menu-enable {
  @include sector-menu-circle();
  background: #007cb0;
  padding: 8px;
  border-radius: 15px;
  color: white;
  margin-bottom: 1px;
}
.sector-menu-not-enable {
  @include sector-menu-circle();
  background: #007cb0;
}

.sector-menu-not-enable-grey {
  @include sector-menu-circle();
  background: rgb(178, 173, 173);
}

.usefull-section-img1 {
  padding: 0px !important;
}
.usefull-section-img1 img {
  height: 280px;
  width: 100%;
  overflow: hidden;
  object-fit: cover;
}
.select-sector-menubar-back {
  padding-top: 11px;
  padding-left: 11px;
  padding-right: 11px;
  padding-bottom: 16px;
}
.select-sector-menubar-back:hover {
  background: #f5f5f5;
}
.select-sector-menubar-front {
  padding-left: 16px;
}
.select-sector-line-blue-left {
  padding-right: 10px;
}
.faq-section-innersection {
  padding-left: 120px;
  padding-bottom: 70px;
}
.storefront-subsection-right-image {
  display: flex !important;
  justify-content: center;
  align-items: center;
  height: 105%;
}

@media screen and (min-width: 1160px) {
  .flex-container .home-page-inner-menubar-text {
    margin-left: 80px !important;
  }
  .home-screen-inner-content {
    padding-left: 90px !important;
  }
  .circle-wrapper {
    margin-left: 440px;
  }
  .storefront-rightsection {
    position: absolute;
    padding-top: 48px;
    padding-left: 40px;
  }
  .Elements-of-DigiPrint {
    font-size: 22px !important;
    width: 420px;
  }
  .Delivery-Across-Deloitte,
  .Delivery-Across-Deloitte p {
    font-size: 15px !important;
    width: 355px;
  }
  
  .storefrontMenu-left-title {
    width: 15% !important;
  }
  .storefrontMenu-right-enablers {
    width: 85% !important;
  }
}
@media screen and (min-width: 1278px) {
  .circle-wrapper {
    margin-left: 508px !important;
  }
  .storefrontMenu-left-title {
    width: 13% !important;
  }
  .storefrontMenu-right-enablers {
    width: 87% !important;
  }
  .storefront-rightsection {
    position: absolute;
    padding-top: 48px;
    padding-left: 79px;
  }
  .Elements-of-DigiPrint {
    font-size: 22px !important;
    width: 435px;
  }
  .Delivery-Across-Deloitte,
  .Delivery-Across-Deloitte p {
    font-size: 15px !important;
    width: 385px !important;
  }
}
@media screen and (min-width: 1280px) {
  .flex-container .home-page-inner-menubar-text {
    margin-left: 92px !important;
  }
  .home-screen-inner-content {
    padding-left: 105px !important;
  }
  .circle-wrapper {
    margin-left: 520px !important;
  }
  .storefront-rightsection {
    position: absolute;
    padding-top: 48px;
    padding-left: 90px;
  }
  .Elements-of-DigiPrint {
    font-size: 22px !important;
    width: 435px;
  }
  .Delivery-Across-Deloitte,
  .Delivery-Across-Deloitte p {
    font-size: 15px !important;
    width: 400px;
  }
  .storefrontMenu-left-title {
    width: 14% !important;
  }
  .storefrontMenu-right-enablers {
    width: 86% !important;
  }
}
@media screen and (min-width: 1420px) {
  .flex-container .home-page-inner-menubar-text {
    margin-left: 110px !important;
  }
  .home-screen-inner-content {
    padding-left: 120px !important;
  }
  .circle-wrapper {
    margin-left: 612px !important;
  }
  .storefront-rightsection {
    position: absolute;
    padding-top: 48px;
    padding-left: 108px;
  }
  .Elements-of-DigiPrint {
    font-size: 23px !important;
    width: 460px !important;
  }
  .Delivery-Across-Deloitte,
  .Delivery-Across-Deloitte p {
    font-size: 15px !important;
    width: 445px !important;
  }
  .storefrontMenu-left-title {
    width: 12% !important;
  }
  .storefrontMenu-right-enablers {
    width: 88% !important;
  }
}
@media screen and (min-width: 1536px) {
  .storefront-rightsection {
    position: absolute;
    padding-top: 48px;
    padding-left: 113px;
  }

  .Elements-of-DigiPrint {
    font-size: 23px !important;
    width: 445px !important;
  }
  .Delivery-Across-Deloitte,
  .Delivery-Across-Deloitte p {
    font-size: 15px !important;
    width: 445px !important;
  }
  .circle-wrapper {
    margin-left: 678px !important;
  }
}
@media screen and (min-width: 1597px) {
  .circle-wrapper {
    margin-left: 723px !important;
  }
  .Elements-of-DigiPrint {
    font-size: 23px !important;
    width: 548px !important;
  }
  .Delivery-Across-Deloitte,
  .Delivery-Across-Deloitte p {
    font-size: 15px !important;
    width: 548px !important;
  }
  .storefrontMenu-left-title {
    width: 11% !important;
  }
  .storefrontMenu-right-enablers {
    width: 89% !important;
  }
}
@media screen and (min-width: 1600px) {
  .flex-container .home-page-inner-menubar-text {
    margin-left: 140px !important;
  }
  .home-screen-inner-content {
    padding-left: 150px !important;
  }
  .circle-wrapper {
    margin-left: 720px !important;
  }
  .storefront-rightsection {
    position: absolute;
    padding-top: 48px;
    padding-left: 135px;
  }
  .Elements-of-DigiPrint {
    font-size: 23px !important;
    width: 530px;
  }
  .Delivery-Across-Deloitte,
  .Delivery-Across-Deloitte p {
    font-size: 15px !important;
    width: 530px;
  }
  .storefrontMenu-left-title {
    width: 11% !important;
  }
  .storefrontMenu-right-enablers {
    width: 89% !important;
  }
}
@media screen and (min-width: 1700px) {
  .flex-container .home-page-inner-menubar-text {
    margin-left: 155px !important;
  }
  .home-screen-inner-content {
    padding-left: 165px !important;
  }
  .circle-wrapper {
    margin-left: 790px !important;
  }
  .storefront-rightsection {
    position: absolute;
    padding-top: 48px;
    padding-left: 153px;
  }
  .Elements-of-DigiPrint {
    font-size: 23px !important;
    width: 600px;
  }
  .Delivery-Across-Deloitte,
  .Delivery-Across-Deloitte p {
    font-size: 15px !important;
    width: 560px;
  }
  .storefrontMenu-left-title {
    width: 10% !important;
  }
  .storefrontMenu-right-enablers {
    width: 90% !important;
  }
}
@media screen and (min-width: 1706px) {
  .Elements-of-DigiPrint {
    font-size: 23px !important;
    width: 600px;
  }
  .Delivery-Across-Deloitte,
  .Delivery-Across-Deloitte p {
    font-size: 15px !important;
    width: 560px;
  }
  .circle-wrapper {
    margin-left: 790px !important;
  }
}
@media screen and (min-width: 1745px) {
  .Elements-of-DigiPrint {
    font-size: 23px !important;
    width: 600px;
  }
  .Delivery-Across-Deloitte,
  .Delivery-Across-Deloitte p {
    font-size: 15px !important;
    width: 560px;
  }
  .circle-wrapper {
    margin-left: 825px !important;
  }
}
@media screen and (min-width: 1745px) {
  .storefront-rightsection {
    position: absolute;
    padding-top: 48px;
    padding-left: 157px;
  }
}
@media screen and (min-width: 1917px) {
  .circle-wrapper {
    margin-left: 930px !important;
  }
  .Elements-of-DigiPrint {
    font-size: 23px !important;
  }
  .Delivery-Across-Deloitte {
    font-size: 15px !important;
  }
}
@media screen and (min-width: 1920px) {
  .flex-container .home-page-inner-menubar-text {
    margin-left: 185px !important;
  }
  .home-screen-inner-content {
    padding-left: 195px !important;
  }
  .circle-wrapper {
    margin-left: 622px;
  }
  .storefront-rightsection {
    position: absolute;
    padding-top: 48px;
    padding-left: 185px;
  }
  .Elements-of-DigiPrint {
    font-size: 23px !important;
    width: 640px;
  }
  .Delivery-Across-Deloitte,
  .Delivery-Across-Deloitte p {
    font-size: 15px !important;
    width: 640px;
  }
  .storefrontMenu-left-title {
    width: 8% !important;
  }
  .storefrontMenu-right-enablers {
    width: 92% !important;
  }
}
@media screen and (min-width: 2131px) {
  .Elements-of-DigiPrint {
    font-size: 23px !important;
    width: 735px !important;
  }
  .Delivery-Across-Deloitte,
  .Delivery-Across-Deloitte p {
    font-size: 15px !important;
    width: 735px !important;
  }
  .circle-wrapper {
    margin-left: 1075px !important;
  }
}
@media screen and (min-width: 2133px) {
  .circle-wrapper {
    margin-left: 1084px !important;
  }
  .Elements-of-DigiPrint {
    font-size: 23px !important;
    width: 700px;
  }
  .Delivery-Across-Deloitte,
  .Delivery-Across-Deloitte p {
    font-size: 15px !important;
    width: 700px;
  }
  .storefront-rightsection {
    position: absolute;
    padding-top: 48px;
    padding-left: 185px;
  }
}
@media screen and (min-width: 2397px) {
  .Elements-of-DigiPrint {
    font-size: 23px !important;
    width: 735px !important;
  }
  .Delivery-Across-Deloitte,
  .Delivery-Across-Deloitte p {
    font-size: 15px !important;
    width: 735px !important;
  }
  .circle-wrapper {
    margin-left: 1260px !important;
  }
}
@media screen and (min-width: 2400px) {
  .circle-wrapper {
    margin-left: 1255px !important;
  }
  .Elements-of-DigiPrint {
    font-size: 23px !important;
    width: 700px;
  }
  .Delivery-Across-Deloitte,
  .Delivery-Across-Deloitte p {
    font-size: 15px !important;
    width: 700px;
  }
}
@media screen and (min-width: 2556px) {
  .circle-wrapper {
    margin-left: 1353px !important;
  }
  .storefront-rightsection {
    position: absolute;
    padding-top: 48px;
    padding-left: 185px;
  }
  .Elements-of-DigiPrint {
    font-size: 23px !important;
  }
  .Delivery-Across-Deloitte {
    font-size: 15px !important;
  }
}
@media screen and (min-width: 2560px) {
  .flex-container .home-page-inner-menubar-text {
    margin-left: 270px !important;
  }
  .home-screen-inner-content {
    padding-left: 278px !important;
  }
  .circle-wrapper {
    margin-left: 935px;
  }
  .storefront-rightsection {
    position: absolute;
    padding-top: 48px;
    padding-left: 268px;
  }
  .Elements-of-DigiPrint {
    font-size: 23px !important;
    width: 890px;
  }
  .Delivery-Across-Deloitte,
  .Delivery-Across-Deloitte p {
    font-size: 15px !important;
    width: 890px;
  }
  .storefrontMenu-left-title {
    width: 6% !important;
  }
  .storefrontMenu-right-enablers {
    width: 94% !important;
  }
}
@media screen and (min-width: 2877px) {
  .Elements-of-DigiPrint {
    font-size: 23px !important;
    width: 735px !important;
  }
  .Delivery-Across-Deloitte,
  .Delivery-Across-Deloitte p {
    font-size: 15px !important;
    width: 735px !important;
  }
  .circle-wrapper {
    margin-left: 1575px !important;
  }
}
@media screen and (min-width: 2880px) {
  .circle-wrapper {
    margin-left: 1573px !important;
  }
}
@media screen and (min-width: 3840px) {
  .circle-wrapper {
    margin-left: 2205px !important;
  }
}
@media screen and (min-width: 3834px) {
  .circle-wrapper {
    margin-left: 2208px !important;
  }
}
@media screen and (min-width: 5120px) {
  .circle-wrapper {
    margin-left: 3045px !important;
  }
}
.faq-section-innersection {
  padding-left: 120px;
}
@media screen and (max-width: 960px) {
  .faq-section-innersection {
    padding-left: 50px;
  }
}

.home-page-inner-menubar-text a {
  text-decoration: none;
  font-family: "OpenSans-Regular";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: 0.02em;
  color: #75787b;
  cursor: pointer;
  padding-top: 17px;
  padding-bottom: 15px;
}

.tab-names-text-active a {
  color: #000000 !important;
  text-underline-offset: 13.4px;
  text-decoration: underline;
  text-decoration-color: var(--color-homepage-menu-active);
  text-decoration-thickness: 4px;
  font-family: "OpenSans-Regular";
}

#welcomedigiprint,
#elementsdigiprint,
#industryselection,
#usefullinks,
#faq,
#contacts {
  position: relative;
  top: -70px;
  visibility: hidden;
  z-index: -10;
}
.circle-wrapper {
  border-radius: 51%;
  position: relative;
  width: 668px;
  height: 642px;
  //background: #eef7f4;
  border: solid 1px rgba(0, 171, 171, 0.2);
  margin-top: 50px;
  margin-bottom: 50px;
  background: #eef7f4;
}
.circle-wrapper1 {
  border-radius: 51%;
  margin: 6em auto 0;
  position: relative;

  width: 462px !important;
  height: 464px !important;

  background: #eef7f4;
  border: solid 1px rgba(0, 171, 171, 0.2);
}

.circle-wrapper2 {
  border-radius: 51%;
  position: relative;
  margin: 3em auto 0;
  width: 370px;
  height: 370px;
  bottom: 462px;
  background: radial-gradient(
      63.34% 74.25% at 50% 4.75%,
      #ffffff 0%,
      #f6f9fb 98.96%
    ),
    #ffffff;
  box-shadow: 0px 30px 50px -10px rgba(7, 39, 39, 0.2);
  border: solid 2px rgba(0, 171, 171, 0.8);
  z-index: 1;
}

.circle-wrapper1 .insidecircle-text {
  font-family: "OpenSans-Regular";
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 25px;
  width: 153px;
  cursor: pointer;
  z-index: 2;
}
.Capability-Storefront {
  text-align: center;
  margin: 8.5em 0px 0 0;
  font-family: "OpenSans-Bold";
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 27px;
  letter-spacing: 0.02em;
  cursor: pointer;
}
.Elements-of-DigiPrint {
  font-family: "OpenSans-Bold";
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 36px;
  letter-spacing: 0.02em;
  flex: none;
  order: 0;
  flex-grow: 0;
  margin-bottom: 5px;
}
.Delivery-Across-Deloitte {
  height: auto;
  font-family: "OpenSans-Regular";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.02em;
  color: #000000;
  flex: none;
  order: 1;
  flex-grow: 0;
}
.storefront-rightside-section-heading p b {
  font-family: "OpenSans-Regular";
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
}
.storefront-rightside-section-subheading {
  font-family: "OpenSans-Regular";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 26px;
}
.storefront-rightside-section-sublevelheading {
  font-family: "OpenSans-Regular";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 26px;
}
.storefront-subsection-left {
  background: #f5f5f5;
}
.storefront-subsection-right {
  background: #ffffff;
  position: relative;
}
.storefront-rightside-section {
  padding: 90px;
  text-align: justify;
}
// .storefront-subsection-right-image {
//   padding-top: 165px;
//   margin-left: -280px;
// }
.storefront-close-icons {
  position: absolute;
  top: 40px;
  right: 60px;
  cursor: pointer;
}

.container {
  width: 20em;
  height: 20em;
  margin: 5em auto 0;
}
.circular {
  position: relative;
  padding: 0 0 50% 0;
  border-radius: 50%;
  list-style: none;
}
.circular > * {
  position: absolute;
  top: 47%;
  left: 47.5%;
  width: 5.77em;
  height: 6em;
  margin-left: 0px;
}

.circular > *:nth-of-type(1) {
  transform: rotate(0) translate(250%);
}
.circular > *:nth-last-child(2):first-child ~ :nth-of-type(2) {
  transform: rotate(-180deg) translate(250%) rotate(180deg);
}
.circular > *:nth-last-child(3):first-child ~ :nth-of-type(2) {
  transform: rotate(-120deg) translate(250%) rotate(120deg);
}
.circular > *:nth-last-child(3):first-child ~ :nth-of-type(3) {
  transform: rotate(-240deg) translate(250%) rotate(240deg);
}
.circular > *:nth-last-child(4):first-child ~ :nth-of-type(2) {
  transform: rotate(-90deg) translate(250%) rotate(90deg);
}
.circular > *:nth-last-child(4):first-child ~ :nth-of-type(3) {
  transform: rotate(-180deg) translate(250%) rotate(180deg);
}
.circular > *:nth-last-child(4):first-child ~ :nth-of-type(4) {
  transform: rotate(-270deg) translate(250%) rotate(270deg);
}
.circular > *:nth-last-child(5):first-child ~ :nth-of-type(2) {
  transform: rotate(-72deg) translate(250%) rotate(72deg);
}
.circular > *:nth-last-child(5):first-child ~ :nth-of-type(3) {
  transform: rotate(-144deg) translate(250%) rotate(144deg);
}
.circular > *:nth-last-child(5):first-child ~ :nth-of-type(4) {
  transform: rotate(-216deg) translate(250%) rotate(216deg);
}
.circular > *:nth-last-child(5):first-child ~ :nth-of-type(5) {
  transform: rotate(-288deg) translate(250%) rotate(288deg);
}
.circular > *:nth-last-child(6):first-child ~ :nth-of-type(2) {
  transform: rotate(-60deg) translate(250%) rotate(60deg);
}
.circular > *:nth-last-child(6):first-child ~ :nth-of-type(3) {
  transform: rotate(-120deg) translate(250%) rotate(120deg);
}
.circular > *:nth-last-child(6):first-child ~ :nth-of-type(4) {
  transform: rotate(-180deg) translate(250%) rotate(180deg);
}
.circular > *:nth-last-child(6):first-child ~ :nth-of-type(5) {
  transform: rotate(-240deg) translate(250%) rotate(240deg);
}
.circular > *:nth-last-child(6):first-child ~ :nth-of-type(6) {
  transform: rotate(-300deg) translate(250%) rotate(300deg);
}
.circular > *:nth-last-child(7):first-child ~ :nth-of-type(2) {
  transform: rotate(-51.42857143deg) translate(250%) rotate(51.42857143deg);
}
.circular > *:nth-last-child(7):first-child ~ :nth-of-type(3) {
  transform: rotate(-102.85714286deg) translate(250%) rotate(102.85714286deg);
}
.circular > *:nth-last-child(7):first-child ~ :nth-of-type(4) {
  transform: rotate(-154.28571429deg) translate(250%) rotate(154.28571429deg);
}
.circular > *:nth-last-child(7):first-child ~ :nth-of-type(5) {
  transform: rotate(-205.71428571deg) translate(250%) rotate(205.71428571deg);
}
.circular > *:nth-last-child(7):first-child ~ :nth-of-type(6) {
  transform: rotate(-257.14285714deg) translate(250%) rotate(257.14285714deg);
}
.circular > *:nth-last-child(7):first-child ~ :nth-of-type(7) {
  transform: rotate(-308.57142857deg) translate(250%) rotate(308.57142857deg);
}
.circular > *:nth-last-child(8):first-child ~ :nth-of-type(2) {
  transform: rotate(-45deg) translate(250%) rotate(45deg);
}
.circular > *:nth-last-child(8):first-child ~ :nth-of-type(3) {
  transform: rotate(-90deg) translate(250%) rotate(90deg);
}
.circular > *:nth-last-child(8):first-child ~ :nth-of-type(4) {
  transform: rotate(-135deg) translate(250%) rotate(135deg);
}
.circular > *:nth-last-child(8):first-child ~ :nth-of-type(5) {
  transform: rotate(-180deg) translate(250%) rotate(180deg);
}
.circular > *:nth-last-child(8):first-child ~ :nth-of-type(6) {
  transform: rotate(-225deg) translate(250%) rotate(225deg);
}
.circular > *:nth-last-child(8):first-child ~ :nth-of-type(7) {
  transform: rotate(-270deg) translate(250%) rotate(270deg);
}
.circular > *:nth-last-child(8):first-child ~ :nth-of-type(8) {
  transform: rotate(-315deg) translate(250%) rotate(315deg);
}
.circular > *:nth-last-child(9):first-child ~ :nth-of-type(2) {
  transform: rotate(-40deg) translate(250%) rotate(40deg);
}
.circular > *:nth-last-child(9):first-child ~ :nth-of-type(3) {
  transform: rotate(-80deg) translate(250%) rotate(80deg);
}
.circular > *:nth-last-child(9):first-child ~ :nth-of-type(4) {
  transform: rotate(-120deg) translate(250%) rotate(120deg);
}
.circular > *:nth-last-child(9):first-child ~ :nth-of-type(5) {
  transform: rotate(-160deg) translate(250%) rotate(160deg);
}
.circular > *:nth-last-child(9):first-child ~ :nth-of-type(6) {
  transform: rotate(-200deg) translate(250%) rotate(200deg);
}
.circular > *:nth-last-child(9):first-child ~ :nth-of-type(7) {
  transform: rotate(-240deg) translate(250%) rotate(240deg);
}
.circular > *:nth-last-child(9):first-child ~ :nth-of-type(8) {
  transform: rotate(-280deg) translate(250%) rotate(280deg);
}
.circular > *:nth-last-child(9):first-child ~ :nth-of-type(9) {
  transform: rotate(-320deg) translate(250%) rotate(320deg);
}
.circular > *:nth-last-child(10):first-child ~ :nth-of-type(2) {
  transform: rotate(-36deg) translate(250%) rotate(36deg);
}
.circular > *:nth-last-child(10):first-child ~ :nth-of-type(3) {
  transform: rotate(-72deg) translate(250%) rotate(72deg);
}
.circular > *:nth-last-child(10):first-child ~ :nth-of-type(4) {
  transform: rotate(-108deg) translate(250%) rotate(108deg);
}
.circular > *:nth-last-child(10):first-child ~ :nth-of-type(5) {
  transform: rotate(-144deg) translate(250%) rotate(144deg);
}
.circular > *:nth-last-child(10):first-child ~ :nth-of-type(6) {
  transform: rotate(-180deg) translate(250%) rotate(180deg);
}
.circular > *:nth-last-child(10):first-child ~ :nth-of-type(7) {
  transform: rotate(-216deg) translate(250%) rotate(216deg);
}
.circular > *:nth-last-child(10):first-child ~ :nth-of-type(8) {
  transform: rotate(-252deg) translate(250%) rotate(252deg);
}
.circular > *:nth-last-child(10):first-child ~ :nth-of-type(9) {
  transform: rotate(-288deg) translate(250%) rotate(288deg);
}
.circular > *:nth-last-child(10):first-child ~ :nth-of-type(10) {
  transform: rotate(-324deg) translate(250%) rotate(324deg);
}
.circular p {
  display: block;
  border-radius: 50%;
  transition: 0.15s;
  width: 100%;
  margin-bottom: 5px;
}
.circular
  > *:nth-last-child(8):first-child
  ~ :nth-of-type(6)
  .insidecircle-text-disable {
  position: relative;
  bottom: 51px;
  width: 180px;
  right: 151px;
}
.circular
  > *:nth-last-child(8):first-child
  ~ :nth-of-type(7)
  .insidecircle-text-disable {
  position: relative;
  bottom: 7px;
  width: 180px;
  right: 56px;
}
.circular
  > *:nth-last-child(8):first-child
  ~ :nth-of-type(8)
  .insidecircle-text-disable {
  position: relative;
  bottom: 51px;
  left: 40px;
}
.circular
  > *:nth-last-child(7):first-child
  ~ :nth-of-type(6)
  .insidecircle-text-disable {
  position: relative;
  top: 8px;
  width: 180px;
  right: 40px;
  cursor: not-allowed;
}
.circular
  > *:nth-last-child(7):first-child
  ~ :nth-of-type(7)
  .insidecircle-text-disable {
  position: relative;
  bottom: 36px;
  left: 40px;
  width: 160px;
  cursor: not-allowed;
}
.circular > *:nth-of-type(1) .insidecircle-text {
  position: relative;
  bottom: 46px;
  left: 32px;
}
.circular
  > *:nth-last-child(8):first-child
  ~ :nth-of-type(8)
  .insidecircle-text {
  position: relative;
  bottom: 36px;
  left: 42px;
}
.circular
  > *:nth-last-child(7):first-child
  ~ :nth-of-type(3)
  .insidecircle-text {
  position: relative;
  bottom: 92px;
  right: 45px;
}
.circular
  > *:nth-last-child(6):first-child
  ~ :nth-of-type(3)
  .insidecircle-text {
  position: relative;
  bottom: 66px;
  right: 110px;
}
.circular
  > *:nth-last-child(5):first-child
  ~ :nth-of-type(2)
  .insidecircle-text {
  position: relative;
  bottom: 94px;
  width: 100px;
  right: 20px;
}
.circular
  > *:nth-last-child(5):first-child
  ~ :nth-of-type(3)
  .insidecircle-text {
  position: relative;
  bottom: 51px;
  right: 125px;
}
.circular
  > *:nth-last-child(3):first-child
  ~ :nth-of-type(2)
  .insidecircle-text {
  position: relative;
  bottom: 94px;
  width: 100px;
  right: 52px;
}
.circular
  > *:nth-last-child(9):first-child
  ~ :nth-of-type(2)
  .insidecircle-text {
  position: relative;
  bottom: 60px;
  left: 30px;
  width: 110px;
}
.circular
  > *:nth-last-child(9):first-child
  ~ :nth-of-type(3)
  .insidecircle-text {
  position: relative;
  bottom: 95px;
  right: 55px;
}
.circular
  > *:nth-last-child(9):first-child
  ~ :nth-of-type(4)
  .insidecircle-text {
  position: relative;
  bottom: 63px;
  right: 143px;
}
.circular
  > *:nth-last-child(9):first-child
  ~ :nth-of-type(5)
  .insidecircle-text {
  position: relative;
  bottom: 63px;
  right: 143px;
}
.circular
  > *:nth-last-child(9):first-child
  ~ :nth-of-type(6)
  .insidecircle-text {
  position: relative;
  bottom: 50px;
  right: 115px;
}
.circular
  > *:nth-last-child(9):first-child
  ~ :nth-of-type(7)
  .insidecircle-text {
  position: relative;
  bottom: 36px;
  right: 144px;
  width: 150px;
}
.circular
  > *:nth-last-child(9):first-child
  ~ :nth-of-type(8)
  .insidecircle-text {
  position: relative;
  top: 5px;
  right: 44px;
  width: 145px;
}
.circular
  > *:nth-last-child(9):first-child
  ~ :nth-of-type(9)
  .insidecircle-text {
  position: relative;
  left: 30px;
  width: 145px;
  bottom: 30px;
}
.circular
  > *:nth-last-child(8):first-child
  ~ :nth-of-type(4)
  .insidecircle-text {
  position: relative;
  bottom: 55px;
  right: 140px;
}
.circular
  > *:nth-last-child(8):first-child
  ~ :nth-of-type(5)
  .insidecircle-text {
  position: relative;
  bottom: 55px;
  right: 125px;
}
.circular
  > *:nth-last-child(8):first-child
  ~ :nth-of-type(6)
  .insidecircle-text {
  position: relative;
  bottom: 36px;
  right: 115px;
}
.circular
  > *:nth-last-child(8):first-child
  ~ :nth-of-type(7)
  .insidecircle-text {
  position: relative;
  bottom: 0px;
  right: 47px;
  width: 145px;
}
.circular
  > *:nth-last-child(8):first-child
  ~ :nth-of-type(8)
  .insidecircle-text {
  position: relative;
  bottom: 44px;
  left: 25px;
  width: 145px;
}
.circular
  > *:nth-last-child(7):first-child
  ~ :nth-of-type(2)
  .insidecircle-text {
  position: relative;
  bottom: 52px;
  left: 50px;
  width: 70px;
}

.circular
  > *:nth-last-child(7):first-child
  ~ :nth-of-type(3)
  .insidecircle-text {
  position: relative;
  bottom: 90px;
  right: 45px;
}

.circular
  > *:nth-last-child(7):first-child
  ~ :nth-of-type(4)
  .insidecircle-text {
  position: relative;
  right: 135px;
  bottom: 45px;
  width: 140px;
}

.circular
  > *:nth-last-child(7):first-child
  ~ :nth-of-type(5)
  .insidecircle-text {
  position: relative;
  bottom: 45px;
  right: 146px;
}

.circular
  > *:nth-last-child(7):first-child
  ~ :nth-of-type(6)
  .insidecircle-text {
  position: absolute;
  right: -10px;
  top: 28px;
}

.circular
  > *:nth-last-child(7):first-child
  ~ :nth-of-type(7)
  .insidecircle-text {
  position: relative;
  bottom: 41px;
  left: 35px;
  width: 150px;
}
.circular
  > *:nth-last-child(2):first-child
  ~ :nth-of-type(2)
  .insidecircle-text {
  position: relative;
  right: 110px;
  width: 100px;
  bottom: 55px;
}

.circular
  > *:nth-last-child(3):first-child
  ~ :nth-of-type(3)
  .insidecircle-text {
  position: relative;
  right: 140px;
  bottom: 39px;
}
.circular
  > *:nth-last-child(4):first-child
  ~ :nth-of-type(2)
  .insidecircle-text {
  position: relative;
  right: 40px;
  width: 100px;
  bottom: 95px;
}
.circular
  > *:nth-last-child(4):first-child
  ~ :nth-of-type(4)
  .insidecircle-text {
  position: relative;
  right: 33px;
  width: 140px;
}

.circular
  > *:nth-last-child(4):first-child
  ~ :nth-of-type(3)
  .insidecircle-text {
  position: relative;
  right: 120px;
  bottom: 55px;
}
.circular
  > *:nth-last-child(5):first-child
  ~ :nth-of-type(5)
  .insidecircle-text {
  position: relative;
  right: 28px;
}
.circular
  > *:nth-last-child(5):first-child
  ~ :nth-of-type(4)
  .insidecircle-text {
  position: relative;
  right: 105px;
  width: 140px;
  bottom: 30px;
}
.circular
  > *:nth-last-child(6):first-child
  ~ :nth-of-type(4)
  .insidecircle-text {
  position: relative;
  right: 135px;
  width: 140px;
  bottom: 51px;
}
.circular
  > *:nth-last-child(6):first-child
  ~ :nth-of-type(2)
  .insidecircle-text {
  position: relative;
  right: 14px;
  width: 100px;
  bottom: 95px;
}
.circular
  > *:nth-last-child(6):first-child
  ~ :nth-of-type(5)
  .insidecircle-text {
  position: relative;
  right: 105px;
  bottom: 30px;
}

.circular
  > *:nth-last-child(8):first-child
  ~ :nth-of-type(2)
  .insidecircle-text {
  position: relative;
  bottom: 50px;
  left: 45px;
  width: 105px;
}
.circular
  > *:nth-last-child(8):first-child
  ~ :nth-of-type(3)
  .insidecircle-text {
  position: relative;
  bottom: 103px;
  right: 30px;
}

.insidecircle-text-disable {
  font-family: "OpenSans-Regular";
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 25px;
}

#industryLogo {
  height: 70px;
  width: 70px;
  background: linear-gradient(
    108.74deg,
    rgba(255, 255, 255, 0.096) 0%,
    rgba(255, 255, 255, 0.0288) 100%
  );
  box-shadow: 0px 0px 52.5641px -26.2821px rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(52.5641px);
  border-radius: 50%;
}

.homepage-banner-first-section {
  display: flex;
  justify-content: center;
  align-items: center;
}
.insidecircle-text-disable-content {
  font-family: "OpenSans-Regular";
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 25px;
  color: #2d2d2d;
}
.storefront-rightside-section-sublevelheading1 p b {
  font-family: "OpenSans-Regular";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 26px;
}

.about-front-container {
  display: flex;
  justify-content: center;
  flex-direction: row;
  margin-left: 10px;

  .storefront-circle-image {
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }
}
.insidecircle-text-disable .storefront-circle-text p b,
.insidecircle-text-disable .storefront-circle-text p:nth-child(2) {
  cursor: not-allowed;
}
.storefront-circle-text p b {
  font-family: "OpenSans-Regular";
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 25px;
  width: 153px;
  cursor: pointer;
  z-index: 2;
  margin-bottom: 0px;
}
.storefront-circle-text p:nth-child(2) {
  font-family: "OpenSans-Regular";
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 25px;
  color: #2d2d2d;
}

.circular > *:nth-of-type(1) .storefront-circle-image {
  position: absolute;
  top: 20px;
  left: 90px;
}
.circular
  > *:nth-last-child(7):first-child
  ~ :nth-of-type(4)
  .storefront-circle-image {
  position: absolute;
  top: 20px;
  left: 90px;
}
.circular
  > *:nth-last-child(7):first-child
  ~ :nth-of-type(5)
  .storefront-circle-image {
  position: absolute;
  top: 20px;
  left: 110px;
}
.Capability-Storefront .Capability-Storefront-icon {
  margin-left: 10px;
  position: relative;
  bottom: 43px;
  left: 127px;
}

.usefull-section-container {
  max-height: 300px;
}
.industry_section_DefaultCircle,
.industry_section_DefaultCircle_default {
  height: 114px;
  width: 114px;
}
.select-industry-section:hover .industry_section_DefaultCircle_default {
  opacity: 0;
  height: 114px;
  width: 114px;
}
.about-contact-subsection-scroll {
  overflow-y: scroll;
  overflow-x: hidden;
  min-height: 400px;
  max-height: 515px;
}
.about-contact-subsection-scroll::-webkit-scrollbar {
  width: 6px;
}
.about-contact-subsection-scroll::-webkit-scrollbar-track {
  border-radius: 5px;
}
.about-contact-subsection-scroll::-webkit-scrollbar-thumb {
  border-radius: 5px;
  background: rgba(0, 0, 0, 0.3);
}
.Delivery-Across-Deloitte,
  .Delivery-Across-Deloitte p {
    text-align: justify !important;
  }

#blogs_color_tooltip,#blogs_color_tooltip1,#blogs_color_tooltip2,#blogs_color_tooltip3 {
  color: white !important;
  font-family: "OpenSans-Regular";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
}

.navbarTransparent {
  width: 100.95%;
  height: 46px;
  background: transparent;
  font-family: "Open Sans";
  margin-left: 0 !important;
  position: fixed;
  right: 0;
  left: 0;
  z-index: 1051;
  opacity: 1;
  transition: none;
  top: 0;
  margin-top: 10px;
}





