
.title{
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 32px;
}

.rightContainer{
    font-family: 'Open Sans';
}

.feedbackRating{
    padding-top: 26px;
    padding-bottom: 14px;
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
}

.feedbackImprovedText {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;    
    display: flex;
    align-items: center;
    padding-top: 32px;
    padding-bottom: 16px;
}

.improve{
    padding: 5px 12px;
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    display: inline-block;
    border-bottom: 2px solid white;
    cursor: pointer;

}

.selectedImprove{
    padding: 5px 12px;
    display: inline-block;
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    border-bottom: 2px solid #0D8390;
    cursor: pointer;
}

.suggestion{
    padding:  9px 16px !important; 
    height: 140px !important;
    margin-top: 4px;
}

.check{
    margin-right: 12px;
    transform: scale(1.4);
    margin-top: 24px;
    color: #0D8390 !important;
}

.check:checked{
    color:#0D8390 !important;
}

.checkboxText{
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
}

.uploadFiles{
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    padding-top: 14px;
}

.format{
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    padding-top: 5px;
    color: #53565A;
}

.uploadButton{
    color: white;
    display: block;
    margin-top: 15px;
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    background: #0D8390;
    padding: 5px 12px;
    border: none;
    border-radius: 4px;
}

.uploadButton:hover{
    background-color: #05454c;
}

.disabledUploadButton{
    border-radius: 4px;
    color: #000000 16%;
    display: block;
    margin-top: 15px;
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    background: #D0D0CE;
    padding: 5px 12px;
    border: none;
}

.emoji{
    cursor: pointer;
    transition: all .3s;
    padding-right: 12px;
}

.submitBtn{
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #FFFFFF;
    background: #0D8390;
    border-radius: 4px;
    padding: 10px 20px;
    border: none;
}

.disabledSubmitButton{
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #000000 16%;
    background: #D0D0CE;
    border-radius: 4px;
    padding: 10px 20px;
    border: none;

}
.footerContainer{
    display: flex;
    justify-content: flex-end;
    margin-top: 30px;
}

.cancelBtn{
    margin-right: 12px;
    padding: 10px 20px;
    border: 1px solid #0D8390;
    border-radius: 4px;
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #0D8390;
    background-color: white;

}

.cancelBtn:hover{
    background-color: #DDEFE8 !important;
    text-decoration: none !important;
}

.submitBtn:hover{
    background-color: #05454c;
}

.submittedCheck{
    width: 80px;
    height: 80px;
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));     
    margin: auto;   
}

.rightContainerSubmitted{
    display: flex;
    flex-direction: column;
    height: 90%;
    justify-content: center;
    font-family: 'Open Sans';
    margin-top: 48px;
}

.thanks{
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    line-height: 32px;
    display: flex;
    align-items: center;
    text-align: center;    
    color: rgba(0, 0, 0, 0.85);
    padding-top: 16px;
    margin: auto;
}

.text{
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    display: flex;
    align-items: center;
    text-align: center;
    color: #53565A;
    padding-top: 8px;
    margin: auto;
}

.okBtn{
    margin-top: 16px;
    width: 53px;
    padding: 9px 16px;
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    text-align: center;
    color: rgba(0, 0, 0, 0.85);
    background: #FFFFFF;
    border: 1px solid #D9D9D9;
    box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.016);
    border-radius: 2px;
    margin: 16px  auto 48px;
}

.okBtn:hover{
    color:#0D8390;
    border: 1px solid #0D8390;
}