.header {
    color: white;
    background-color: black;
    padding: 120px 40px 64px 40px;
    font-size: 40px;
    line-height: 52px;
    font-weight: 600;
}

.sub-header {
    font-size: 24px;
    font-weight: 600;
    line-height: 36px;
    margin-bottom: 40px;
}