.container{
    position: fixed;
    bottom: 0;
    z-index: 7;
    width: 100%;
    margin-left: -1px !important;
    transition-property: margin-bottom;
    transition-duration: 0.5s;
}

.containerHeader{
    padding: 22px 40px 20px 34px;
    background-color: #041E42;
    color: white;
    margin-left: 0 !important;
}

.containerBody{
    background-color: white;
    margin-left: 0 !important;
    z-index: 9;
    color: black;
    padding: 16px 28px 40px 28px;
    width: 100%;
}

.selected{
    padding-left: 15px;
    font-size: 16px;
    font-family: 'Open Sans';
}

.buildDigiprint {
    border-radius: 4px;
    background-color: #0D8390;
    color: white;
    padding: 8px 18px;
    max-width: 163px;
    border: none;
    font-weight: lighter;
    float: right;
    margin-top: -4px;
    font-size: 14px;
}

.buildDigiprint:hover {
    background-color: #05454c;
    transition: 0.5s;
}

.capabilityContainer{
    padding: 8px 8px 8px 16px;
    background-color: #9dd4cf;
    border-radius: 4px;
    font-weight: 500 !important;
    margin: 8px 0px;
    font-size: 14px;
    font-family: 'Open Sans';
}

.capabilityName{
    display: inline-block !important;
    width: 94% !important;
}

.minusIcon{
    display: inline-block !important;
    width:6%;
    float: right;
    cursor: pointer;
}

.valueChain{
    padding: 24px 0px 8px 0px;
    font-weight: 900 !important;
    font-family: 'Open Sans';
    font-size: 14px;
}