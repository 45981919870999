.rotateAnti{
    transform: rotate(-180deg);
    transition: transform 0.5s linear;
}

.rotate{
    transform: rotate(0deg);
    transition: transform 0.5s linear;
}

.roundBorder{
    background-color: transparent;
    border-radius: 50%;
    //padding: 0px 1px;
    display: inline-block;
    width: 23px;
    height: 23px;
    text-align: center;
}

.roundBorder:hover {
    background-color: #d0d0ce;
    border-radius: 50%;
    //padding: 0px 1px;
    width: 23px;
    height: 23px;
    text-align: center;
}