.container{
    background-color: #f4f4f4 !important;
    padding:  16px 70px 16px 70px !important;
    width: 100% !important;
}

.cardContainer{
    border-top: 8px solid #ddefe8;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    background-color: white;
    font-family: 'Open Sans' !important;
    margin: 24px 0px;
}

.leftSectionContainer{
    padding: 48px 40px;
    width: 25% !important;
    border-right: 2px solid #f4f4f4;
    font-family: 'Open Sans';
}

.name{
    font-size: 24px;
    padding-top: 24px;
    font-weight: 900;
    word-wrap: break-word !important;
}

.date{
    color:#75787b;
    font-size: 12px;
}

.assetInventory, .services{
    padding-top: 40px;
    font-size: 16px;
}

.inventoryButton{
    border-radius: 4px;
    border: 1px solid #0d8390;
    color: #0d8390;
    padding: 10px 14px 10px 20px; 
    background-color: white;
    font-size: 14px;
    margin-top: 12px;
    cursor: pointer;
}

.inventoryButton:hover{
    background-color: #ddefe8;
    transition: 0.5s;
}

.visitSite{
    border-radius: 4px;
    background: #0d8390;;
    border: 2px solid #0d8390;
    color: #0d8390;
    padding: 10px 20px 10px 20px; 
    background-color: white;
    font-size: 20px;
    margin-top: 12px;
}

.arrow{
    margin-left: 12px;
    display: inline-block;
}

.rightSectionContainer{
    padding: 96px 49px 56px 49px;
    width: 75% !important;
    border-right: 2px solid #f4f4f4;
    font-family: 'Open Sans';
}

.contacts{
    font-size: 14px;
    font-family: 'Open Sans Semibold';
    padding-top: 40px;
    font-weight: 600;
    padding-bottom: 16px;
}

.collateral{
    font-size: 14px;
    padding-top: 40px;
    font-weight: 600;
}

.readMore{
    color: #0D8390;
    border: 1px solid #0D8390; 
    font-size: 12px;
    border-radius: 4px;
    padding: 6px 8px 8px 8px;
    margin-top: 14px;
}

.readMore:hover{
    background-color: #DDEFE8 !important;
    text-decoration: none !important;
    color: #0D8390 !important;
    border: 1px solid #0D8390 !important; 
}
