.tag {
    display: flex;
    justify-content: space-between;
    height: 25px;
    width: fit-content;
    
    margin-inline-end: 4px;
    margin-block-end: 4px;
    border-radius: 4px;     
    padding: 0;

    background: var(--color-teal-bg);
    color: var(--color-teal);
    
    font-size: 12px;
    white-space: nowrap;
}

.content {
    padding: 4px 8px;
}

.close {
    height: 25px;
    width: 25px;
    border-radius: 4px;   
    padding:7px; // width: 24 = 7 (left pad) + 10px + 7 (right pad)
    border: none;
    background: transparent;
    color: var(--color-teal);
    line-height: 0;
    cursor: pointer;
    transition: all 0.2s;
    vertical-align: middle;
}

.close:hover {
    background-color: var(--color-teal-hover);  
    border-radius: 4px;  
}