.leftSectionContainer{
    padding: 80px 40px 0px 40px;
    background-color: #e9e9e9;
    font-family: 'Open Sans' !important;
    width: 33% !important;
    position: relative;
    color:black;
}

.valueChain{
    font-size: 14px;
    letter-spacing: 0.2em;
    text-transform: uppercase;
    font-weight: 600;
}

.capabilityName{
    font-size: 24px;
    padding: 24px 0px;
    white-space: initial;
    word-wrap: break-word;
}

.showAtBottom{
    position: absolute;
    bottom: 50px;
}
.header{
    padding-top:25px;
    padding-bottom: 16px;
    font-weight: 600;
    font-size: 15px;
}

.tag{
    background:white !important;
    color:black !important;
    border-radius:15px !important;
    border: 1.5px solid #e9e9e9 !important;
    height: 30px !important;
    padding: 0px 4px 6.5px 4px !important;
    font-size: 12px !important;
}

.value{
    color: #6FC2B4;
    background-color: rgb(221, 239, 232);
    padding: 3px 6px;
    border-radius: 4px;
    font-size: 12px;
    margin-right: 4px;
    margin-top: 5px;
    display: inline-block;
    width: fit-content;
}




.middleSectionContainer{
    width: 42%;
    padding: 80px 0px 128px 70px;
}

.capabilityTitle {
    font-family: var(--font-family);
    font-style: var(--font-style);
    font-weight: bold;
    font-size: 15px;
    color: #000000;
    font-family: "Open Sans";
}

.bnvTitle {
    font-family: 'Open Sans';
    font-weight: 600;
    font-size: 15px;
    line-height: 150%;
    color: #000000;
    padding-top: 25px;
}

.benefitOrValue{
    margin-top: 15px !important;
    display: flex;
}

.detailsText {
    text-align: justify;
    font-family: "Open Sans";
    white-space: pre-line;
    font-size: 14px;
    font-weight: 400;
    margin-left: 10px;
    color: #63666a;
}

.ecosysStyle, .materials, .subCapability {
    font-family: var(--font-family);
    font-style: var(--font-style);
    font-weight: 600;
    font-size: 15px;
    color: #000000;
    font-family: "Open Sans";
}

.rightSectionContainer{
    width: 25% !important;
    padding: 128px 70px 0px 25px;
}

.subCapName{
    font-size: 15px;
    padding-bottom: 16px;
}