// .admin-layout {
//   padding-top: 100px;
//   padding-bottom: 100px;
// }
.admin-layout .nav-pills .nav-link.active{
  color: #fff !important;
  font-family: "OpenSans-Regular";
}
.admin-layout .nav-link {
  font-family: "OpenSans-Regular";
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    padding: 10px;
    color: #000;
}
.admin-layout .nav-pills {
  --bs-nav-pills-link-active-bg: #86bc25 !important;
  --bs-nav-pills-border-radius:0px;
  font-family: "OpenSans-Regular";
}
.admin_navbar_left_color {
  background: linear-gradient(0deg, rgba(255, 255, 255, 0.5), rgba(255, 255, 255, 0.5)), #ddefe8;
  padding: 0;
  min-height: 70vh;
}
.admin_navbar_left {
padding-top: 70px;
padding-bottom: 100px;
}
.admin_navbar_right {
  padding-top: 50px;
  padding-bottom: 50px;
  padding-left: 30px;
}
.admin_heading_data {
  padding-top: 68px;
    padding-left: 50px;
    font-family: "OpenSans-Bold";
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    color: #000;
}
.adminreport-download-btn {
    display: flex;
    align-items: center;
    // padding: 9px 16px 9px 12px;
    width: 135px;
    height: 37px;
    background: #00abab;
    cursor: pointer;
    font-family: "OpenSans-Bold";
  }
  .storefront-download-option {
    padding: 10px 14px 10px 10px;
  }
  
  .adminsetting-download-option-text {
    font-family: "OpenSans-Bold";
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    color: #ffffff;
    padding-left: 20px;
  }
  .storefront-download-option:hover {
    border: none !important;
  }
  .fa-download,.fa-upload {
    font-size: 14px;
    color: white;
    position: relative;
    left: 10px;
}
.admindownload-tabs-underline {
    width: 85%;
    margin: 55px 0;
    color: #b1a9a9;
}

.error-alert {
  width: 400px;
    margin-left: 20px;
    text-align: center;
    margin-top: 15px;
}

.text-label {
  color: "black";
  padding-left: 20px;
  font-size: 18px;
  font-family: "OpenSans-Regular";
  font-weight: bolder;
}

.error-fonts {
  color: red;
}
.rediscache-download-btn {
  display: flex;
    align-items: center;
    width: 135px;
    height: 37px;
    background: #00abab;
    cursor: pointer;
}
.radiscache_navbar_right {
  padding-top: 28px;
    padding-bottom: 50px;
    padding-left: 54px
}
.redissuccess-alert,.rediserror-alert {
  position: relative;
    bottom: 38px;
    left: 175px;
    height: 41px;
    width: 275px;
    text-align: center;
}
.redissuccess-alert p,.rediserror-alert p{
  position: relative;
    bottom: 7px;
}