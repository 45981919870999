.collapse{
    background-color: #f4f4f4 !important;
    border-color: #f4f4f4 !important;
    width: 100%;
    margin:2% 0% !important;
    border-radius: 4px !important;
}

.capabilityContainer{
    padding:6px  !important;
    border-bottom: 1px solid #f4f4f4;
    cursor: default;
}

.capabilityContainerNoBorder{
  padding:6px  !important;
  border-bottom: 1px solid #f4f4f4;
  cursor: default;
}

.capabilityFiltered{
  padding:6px  !important;
  border-bottom: 2px solid #f4f4f4;
  opacity: 0.7;
  background-color: #6FC2B4;
}

.capabilityHide{
  display: none;
}

.capability{
    padding: 10px 5px 10px 10px;
    border-radius: 6px;
    border: 1px solid white;
}

.capability:hover{
    padding: 10px 5px 10px 10px;
    border-radius: 6px;
    background-color:  #DDEFE8;
}

.capabilitySelected{
  background-color:  green !important;
}

.capabilityNotSelected{
  background-color:  white !important;
}

.capabilityName{
    // cursor: pointer !important;
    width:90% !important;
    display: inline-block;
}

.capabilityName:hover{
  text-decoration: underline !important;
  cursor: pointer;
}

.plusIcon{
    //display: inline-block;
    float: right;
    text-align: center;
    margin-right: 6px;
    cursor: pointer;
}

.dragIcon{
    display: inline-block;
    position: relative;
    top: -3px;
    cursor: pointer;
}

.HoverCardContent {
    border-radius: 4px;
    width:380px;
    font-size: 15px;
    line-height: 1;
    color: var(--violet11);
    background-color: white;
    box-shadow: hsl(206 22% 7% / 35%) 0px 10px 38px -10px, hsl(206 22% 7% / 20%) 0px 10px 20px -15px;
    user-select: none;
    animation-duration: 400ms;
    animation-timing-function: cubic-bezier(0.16, 1, 0.3, 1);
    will-change: transform, opacity;
  }

  .HoverCardContent[data-state='delayed-open'][data-side='top'] {
    animation-name: slideDownAndFade;
  }

  .HoverCardContent[data-state='delayed-open'][data-side='right'] {
    animation-name: slideLeftAndFade;
  }

  .HoverCardContent[data-state='delayed-open'][data-side='bottom'] {
    animation-name: slideUpAndFade;
  }

  .HoverCardContent[data-state='delayed-open'][data-side='left'] {
    animation-name: slideRightAndFade;
  }

  @keyframes slideUp {
    from {
      opacity: 0;
      transform: translateY(10px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }
  
  @keyframes slideDown {
    from {
      opacity: 0;
      transform: translateY(-10px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }
  
  .valueChainName{
    font-family: 'Open Sans Semibold';
    font-size: 14px;
    line-height: 20px;
  }