.emailDiv {
    width: 236px;
    height: 32px;
    font-size: 14px;
    font-family: 'Open Sans';
    color: #0D8390;
    display: flex;
}

.emailDiv a:hover {
    text-decoration: none;
    color: #0D8390;
}

.emailDiv:hover .copyIcon {
    display:inline;
}

.copyIcon {
    display: none;
    cursor: pointer;
}