.logs-layout {
    margin-top: 20px;
    padding: 80px;
}

.log-tr {
    cursor: pointer;
}

.log-modal {
    position: relative;
    top: 22px;
}

.textarea-log-css {
    min-height: 200px !important;
}

@media screen and (max-width: 1745px) {
    .modal-body-logs {
        overflow-x: hidden;
        overflow-y: scroll;
        height: 450px;
    }
}