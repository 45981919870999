.search-container {
    margin-top: 80px;
    margin-bottom: 50px;
    margin-left:40px;
    margin-right:40px;
    height: auto;
    display: flex;
    flex-direction: column;

    .search-input-container {
        width: 50%;
        display: flex;
        justify-content: center;
        margin: 0 auto;
    }
}

.ml-10{
    margin-left: 10px;
}
.mt-20{
    margin-top: 20px;
}
.result-container{
    margin-top: 40px;
    margin-bottom: 16px;
}
.grid-col-2{
    float: left;
    clear: none;
    width: 20%;
}

.grid-col-8{
    float: left;
    clear: none;
    width: 80%;
}


.left-section{
    border: solid #d0d0ce;
    border-width: 0 1px 0 0;
    height: 100%;
}
.right-section{
    padding-left:3em;
}
.filter-list{
    list-style-type: none;
    margin: 0px 0px 0px 0px;
    padding: 0;

    li{
        line-height: 1.5;
        margin: 0 0 1em 0;
        word-wrap: break-word;

            a{
                display: flex;
                align-items: center;
                position: relative;
                min-height: 2.6em;
                padding: .3em .75em 0;
                margin-bottom: 2px;
                color: #000;
                cursor: pointer;
                transition: none;
                box-sizing: border-box;
                -webkit-user-select: none;
                -ms-user-select: none;
                user-select: none;
                text-decoration: none;
            }
            .active{
                background-color: #92ba44;
                font-weight: 700;
                cursor: default;
                text-decoration: none;
            }
    }
}

.results-cards-list{
    margin: 0;
    list-style-type: none;

    li{
            margin-bottom: 0;
            line-height: 1.5;
            margin: 0 0 1em 0;
            word-wrap: break-word;

            .list-item{
                padding: 2em 0;
                border-bottom: 1px solid #d0d0ce;

                a,a:hover{
                    line-height: 1.555;
                    transition: none;
                    color: #007cb0;
                    cursor:pointer
                }

                
            }
    }
}

.btn-custom{
    width: 100%;
    background: white;
    padding: 0.5rem;
    margin: 0.25rem;
}

%loading-skeleton {
    color: transparent;
    appearance: none;
    -webkit-appearance: none;
    background-color: #eee;
    border-color: #eee;
  
    &::placeholder {
      color: transparent;
    }
  }
  @keyframes loading-skeleton {
    from {
      opacity: .4;
    }
    to {
      opacity: 1;
    }
  }
  .loading-skeleton {
    pointer-events: none;
    animation: loading-skeleton 1s infinite alternate;
    
    img {
      filter: grayscale(100) contrast(0%) brightness(1.8);
    }
    h1, h2, h3, h4, h5, h6,
    p, li,div,
    .btn,
    label,
    .form-control {
      @extend %loading-skeleton;
    }
  }

  .descripton-box{
    height:50px
  }