.sticky {
  position: fixed;
  top: 46px;
  width: 100%;
  z-index: 5;
  background: white;
  border-bottom: 1px solid rgb(219, 207, 207);
}

.tabsContainer{
  width: 100%;
  background-color: white;
  font-family: 'Open Sans';
  padding-left: 30px;
}

.tabName{
  margin: 20px 15px;
  padding: 5px 0px 7px 0px;
  display: inline-block;
  font-family: 'Open Sans Semibold';
  font-size: 14px;
}

.tabNameHover{
    border-bottom: 3px solid #F4F4F4;
    padding: 5px 0px 5px 0px;
    cursor: pointer;
}

.tabNameSelected{
  border-bottom: 3px solid #0D8390;
  padding: 5px 0px 5px 0px;
}
