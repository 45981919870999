.buildDigiprint {
    border-radius: 4px;
    background-color: #0D8390;
    color: white;
    padding: 8px 18px;
    max-width: 163px;
    border: none;
    font-weight: lighter;
    float: right;
    margin-top: -4px;
    font-size: 14px;
}
.buildDigiprint:hover {
    background-color: #05454c;
    transition: 0.5s;
}
.loading-text{
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    vertical-align: top;
    color: #FFFFFF;
    padding-right: 30px;
}
.build-digiprint-container{
    float: right
}
.loading-icon{
    width: 34px;
    height: 34px;
    font-size: 10px;
    color: #1890FF;
}
.icon-div{
    padding-right: 23px;
}
.loading-div{
    visibility: hidden;
}
