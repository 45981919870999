.notfound {
    height: 100vh;
    background-color: #000000;
}
.notfound-parentdiv {
    width: 100%;
    text-align: center;
    line-height: 1.4;
}
.notfound-childdiv {
   padding: 8em 0px 0 0;
}
.notfound-childdiv h1{
    font-family: "OpenSans-Bold";
    color: #000000;
    font-size: 220px;
    letter-spacing: 10px;
    margin: 0px;
    font-weight: 700;
    text-shadow: 2px 2px 0px #00abab, -2px -2px 0px #00abab, 0px 0px 8px #00abab;
}
.notfound-parentdiv p {
    color: #fafafa;
    font-size: 20px;
    font-weight: 400;
    margin-top: 0px;
    margin-bottom: 25px;
    font-family: "OpenSans-Regular";
}
.notfound-parentdiv a
{
    font-family: "OpenSans-Regular";
    font-size: 14px;
    text-decoration: none;
    background: transparent;
    color: #fafafa;
    border: 2px solid #fafafa;
    display: inline-block;
    padding: 10px 25px;
    font-weight: 700;
    -webkit-transition: 0.2s all;
    transition: 0.2s all;
}