.modal-description {
  font-family: var(--font-family);
  font-family: var(--font-family);
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  text-align: justify;
  font-family: "OpenSans-Regular";
}

.modal-tabs-layout {
  margin-top: 20px;
}

.modal-tab-headings {
  font-family: var(--font-family);
  font-family: var(--font-family);
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: #97999b;
  margin-top: 10px;
  margin-bottom: 0px;
  font-family: "OpenSans-Regular";
}

.modal-tab-title {
  font-family: var(--font-family);
  font-family: var(--font-family);
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  font-family: "OpenSans-Regular";
}

.capabilities-layout {
  margin-top: 20px;
}

.capabilities {
  cursor: pointer;
}

.capabilities-title {
  font-family: var(--font-family);
  font-style: var(--font-style);
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: #97999b;
  font-family: "OpenSans-Regular";
}

.capability {
  font-family: var(--font-family);
  font-style: var(--font-style);
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  font-family: "OpenSans-Regular";
}

.capability-icon {
  position: relative;
  left: -8px;
}

.capability-link-arrow {
  padding-top: 12px;
}

.capability-underline {
  margin-top: 7px;
  color: #b1a9a9;
}

.modal-body-content {
  padding: 10px 30px;
  overflow-x: hidden;
  overflow-y: scroll;
  height: 630px;
  font-family: "OpenSans-Regular";
}

.modal-body-content::-webkit-scrollbar {
  width: 6px;
}
.modal-body-content::-webkit-scrollbar-track {
  border-radius: 5px;
}
.modal-body-content::-webkit-scrollbar-thumb {
  border-radius: 5px;
  background: rgba(0, 0, 0, 0.3);
}

@media screen and (max-width: 1536px) {
  .modal-body-content {
    height: 495px;
  }
}

@media screen and (max-width: 1423px) {
  .modal-body-content {
    height: 430px;
  }
}

@media screen and (max-width: 1278px) {
  .modal-body-content {
    height: 370px;
  }
}
.capability-individual {
  padding: 5px 5px 0px 5px;
}
.capability-individual:hover {
  cursor: pointer;
  background: #F5F5F5;
}