.card-layout-storefront {
  background-color: #ffffff;
  padding-left: inherit;
  padding-right: inherit;
  padding-top: 30px;
  padding-bottom: 1px;
  // cursor: pointer;
  margin-top: 20px;
  border: none;
  border-radius: 0;
  width: 100%;
}

.card-title {
  font-family: "OpenSans-Regular";
  font-style: normal;
  font-weight: 600;
  font-size: 17px;
  line-height: 25px;
  padding-bottom: 15px;
  color: #000000;
}

.storefrontMenu-card-text {
  font-family: "OpenSans-Regular";
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 25px;
  color: #53565a;
}

.link-text {
  font-family: "OpenSans-Regular";
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 25px;
  color: var(--color-links);
}

.link-arrow {
  width: 18px;
  margin-left: 5px;
}

.card-logo {
  width: 40px;
  height: 40px;
  background: linear-gradient(108.74deg,
      rgba(160, 220, 255, 0.384) 0%,
      rgba(160, 220, 255, 0.1152) 100%);
  box-shadow: 0px 0px 50px -25px rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(100px);
  border-radius: 24px;
  margin-bottom: 10px;
}

.card-comp-footer {
  font-family: "OpenSans-Regular";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: #97999b;
  margin-top: 40px;
}

.storefront-text-font {
  font-family: "OpenSans-Regular";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: #000000;
  padding-left: 37px;
}

.hover-effect:hover {
  cursor: pointer !important;
  background: #F5F5F5;
}

.storefront-card-text-padding {
  // padding-top: 10px;
}

.custom-highlight-class {
  background-color: #ddefe8;
  color: #000000;
  // padding-top: 10px;
  width: 100%;
  margin-left: 0px;
}

.custom-highlight-class .storefront-text-font {
  padding-left: 27px !important;
}

.images {
  z-index: 1;
  opacity: 0.2;
}

.disply-high-index {
  z-index: 1030;
  opacity: 1;
}

.card-layout-storefront .card-logo {
  margin-left: 20px;
}

.card-layout-storefront .card-title {
  padding-left: 23px;
}

.storefrontment-card-bottom {
  border-bottom: 1px solid #f5f5f5;
  // cursor: default;
  // margin-bottom: -10px;
}

@media screen and (min-width: 1160px) {
  .storefrontment-card-bottom {
    padding-top: 10px;
    margin-left: 22px;
    width: 295px;
  }
}

@media screen and (min-width: 1280px) {
  .storefrontment-card-bottom {
    padding-top: 10px;
    margin-left: 22px;
    width: 325px;
  }
}

@media screen and (min-width: 1420px) {
  .storefrontment-card-bottom {
    padding-top: 10px;
    margin-left: 22px;
    width: 370px;
  }
}

@media screen and (min-width: 1600px) {
  .storefrontment-card-bottom {
    padding-top: 10px;
    margin-left: 22px;
    width: 422px;
  }
}

@media screen and (min-width: 1700px) {
  .storefrontment-card-bottom {
    padding-top: 10px;
    margin-left: 22px;
    width: 455px;
  }
}

@media screen and (min-width: 1920px) {
  .storefrontment-card-bottom {
    padding-top: 10px;
    margin-left: 22px;
    width: 515px;
  }
}

@media screen and (min-width: 2560px) {
  .storefrontment-card-bottom {
    padding-top: 10px;
    margin-left: 22px;
    width: 665px;
  }
}

.storefront-card-text-padding {
  .custom-highlight-class-fontblur {
    opacity: 0.7;
    color: #97999b;
    // cursor: default;
  }

  .custom-highlight-class-fontblur-image {
    // cursor: default;
  }
}


@media screen and (min-width: 1160px) {
  .storefrontMenu-card-text .nodata-card {
    padding-left: 75px;
    padding-bottom: 2px;
    // padding-top: 100px;
    // height: 330px;
  }
}

@media screen and (min-width: 1270px) {
  .storefrontMenu-card-text .nodata-card {
    padding-left: 100px;
    padding-bottom: 2px;
    // padding-top: 52px;
    // height: 255px;
  }
}

@media screen and (min-width: 1280px) {
  .storefrontMenu-card-text .nodata-card {
    padding-left: 90px;
    padding-bottom: 2px;
    // padding-top: 45px;
    // height: 255px;
  }
}

@media screen and (min-width: 1420px) {
  .storefrontMenu-card-text .nodata-card {
    padding-left: 110px;
    padding-bottom: 2px;
    // padding-top: 30px;
    // height: 235px;
  }
}

@media screen and (min-width: 1600px) {
  .storefrontMenu-card-text .nodata-card {
    padding-left: 145px;
    padding-bottom: 2px;
    // padding-top: 30px;
    // height: 235px;
  }
}

@media screen and (min-width: 1920px) {
  .storefrontMenu-card-text .nodata-card {
    padding-left: 185px;
    padding-bottom: 2px;
    // padding-top: 30px;
    // height: 235px;
  }
}

@media screen and (min-width: 2560px) {
  .storefrontMenu-card-text .nodata-card {
    padding-left: 285px;
    padding-bottom: 2px;
    // padding-top: 30px;
    // height: 235px;
  }
}

.storefrontMenu-card-text .nodata-card-text {
  padding-top: 25px;
  font-family: "OpenSans-Regular";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #53565a;
  opacity: 0.5;
}

.storefront-card-text-padding:last-child .storefrontment-card-bottom {
  border: none;
}

.custom-highlight-class-fontblur-image img {
  opacity: 0.2;
}

.custom-highlight-class-fontblur-image-align img,
.custom-highlight-class-fontblur-image img {
  margin-left: -2px;
}

.capability-span-padding {
  padding-top: 8px;
  // padding-bottom: 8px;
  margin-bottom: 0px !important;
}