.capabilityTitle {
    font-family: var(--font-family);
    font-style: var(--font-style);
    font-weight: bold;
    font-size: 24px;
    line-height: 36px;
    margin-left: 10px;
    color: #000000;
    font-family: "Open Sans";
}

.detailsArea {
    margin-top: 3.5rem;
    padding: 0rem 0.8rem;
}

.bnvTitle {
    font-family: var(--font-family);
    font-style: var(--font-style);
    font-weight: bold;
    font-size: 24px;
    line-height: 36px;
    margin-left: -0.1rem;
    margin-bottom: 1.5rem;
    color: #000000;
    font-family: "Open Sans";
}

.detailsText {
    text-align: left;
    font-family: "Open Sans";
    white-space: pre-line;
    font-size: 16px;
    padding-left: 0.5rem;
}

.ecosysStyle {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 32px;
    margin-left: 10px;
    margin-top: 64px;
    margin-bottom: 0px !important;
}

.supportingMaterials{
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 32px;
    margin-top: 64px;
    margin-bottom: 24px;
}
