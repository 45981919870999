.capabilityAddButton {
    border-radius: 4px;
    background-color: #0D8390;
    color: white;
    padding: 10px 15px 10px 0px;
    border: none;
    //padding-left: 0px;
    font-weight: lighter;
    font-size: 14px;
    font-family: "Open Sans";

}

.capabilityAddButton:hover {
    background-color: #05454c;
}

.learn {
    border-radius: 4px;
    background-color: rgba(52, 52, 52, 0);
    color: white;
    padding: 10px 8px 10px 8px;
    border: '1px solid white';
    padding-left: 8px;
    font-weight: lighter;
    font-size: 14px;
    font-family: "Open Sans";
}

.learn:hover {
    background-color: white;
    color: #0D8390;
}

.capabilityAddButtonDigiPrintAdded {
    border-radius: 4px;
    background-color: white !important;
    color: #0D8390 !important;
    justify-content: center;
    align-items: center;
    border: 1px solid #0D8390;
    padding: 5px 8px 5px 8px;
    font-size: 14px;
    font-family: "Open Sans";
}

.capabilityAddButtonDigiPrintAdded:hover {
    background-color: #d0efed !important;
}

.addButtonIcon {
    margin: 0px 12px;
}