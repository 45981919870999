.filterDiv {
    align-items: left;
    //transition: width 3s;
    width: 16rem;
    border-color: rgb(237, 235, 235);
    border-radius: 5px;
    margin-top: 1rem;
    margin-left: -10px;
    padding: 0.5rem;
    transition-property: margin-left;
    transition-duration: 0.5s;
}

.filterButton {
    padding: 4px 2px;
    border-color: #D0D0CE;
    width: 2.5rem;
    height: 2.5rem;
    border-radius: 4px;
}

.filterButtonWithCount {
    padding: 4px 2px;
    border-color: #D0D0CE;
    background-color: rgb(13,131,144) ;
    width: 2.5rem;
    height: 2.5rem;
    border-radius: 4px;
}

.imgWhite {
    background-color: white;
}

.imgTeal {
    background-color: rgb(13,131,144) ;
}

.previewArea {
    width: 18rem;
    transition-property: margin-left;
    transition-duration: 0.5s;
}

.resetBtn {
    padding: 1rem 0.5rem ;
}