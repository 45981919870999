.container{
    border-radius: 6px ;
    -webkit-box-shadow: 0 7px 6px -6px #777;
    -moz-box-shadow: 0 7px 6px -6px #777;
    box-shadow: 0px 4px 12px rgba(0, 92, 92, 0.08);
    background-color: white;
    padding: 30px 40px 40px 40px;
    margin-bottom: 2rem;
}

.about{
    font-size: 24px;
    font-weight: 700;
    font-family: Open Sans;
}

.aboutTag{
    border: 1.5px solid #E9E9E9 !important;
    background: white !important;
    padding: 3px 4px !important;
    line-height: 16px !important;
    color: black !important;
    font-weight: 400 !important;
}

.header{
    padding-top:40px;
    padding-bottom: 16px;
    font-weight: 600;
    font-size: 16px;
}

.value{
    color: #6FC2B4;
    background-color: rgb(221, 239, 232);
    padding: 3px 6px;
    border-radius: 4px;
    font-size: 12px;
    margin-right: 4px;
    margin-top: 5px;
    display: inline-block;
    width: fit-content;
}

.maturity{
    font-size: 12px;
    padding-top: 5px;
}

.maturityFirstWhitespace{
    height: 24px;
    width: 33%;
    border: 1px solid #e9e9e9;
    border-radius: 30px 0px 0px 30px;
    background-color: #A0DCFF;
    display: inline-block;
}

.maturityFirstPOC{
    height: 24px;
    width: 33%;
    border: 1px solid #e9e9e9;
    border-radius: 30px 0px 0px 30px;
    background-color: #E3E48D;
    display: inline-block;
}

.maturityFirstMarketready{
    height: 24px;
    width: 33%;
    border: 1px solid #e9e9e9;
    border-radius: 30px 0px 0px 30px;
    background-color: #6FC2B4;
    display: inline-block;
}

.maturityMiddlePOC{
    height: 24px;
    width: 33%;
    border-top: 1px solid #e9e9e9;
    border-bottom: 1px solid #e9e9e9;
    background-color: #E3E48D;
    display: inline-block;
}

.maturityMiddleMarketready{
    height: 24px;
    width: 33%;
    border-top: 1px solid #e9e9e9;
    border-bottom: 1px solid #e9e9e9;
    background-color: #6FC2B4;
    display: inline-block;
}

.maturityRightSelected{
    height: 24px;
    width: 33%;
    border-radius: 0px 30px 30px 0px;
    background-color: #6FC2B4;
    border: 1px solid #e9e9e9;
    display: inline-block;
}

.maturityFirstUnSelected{
    height: 24px;
    width: 33%;
    border: 1px solid #e9e9e9;
    border-radius: 30px 0px 0px 30px;
    display: inline-block;
}

.maturityMiddleUnSelected{
    height: 24px;
    width: 33%;
    border-top: 1px solid #e9e9e9;
    border-bottom: 1px solid #e9e9e9;
    display: inline-block;
}

.maturityRightUnSelected{
    height: 24px;
    width: 33%;
    border-radius: 0px 30px 30px 0px;
    border: 1px solid #e9e9e9;
    display: inline-block;
}