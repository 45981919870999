.capabilityBreadcrumb {
    display: flex;
    flex-direction: row;
}

.capabilityBreadcrumbItem {
    display: flex;
    font-family: 'Open Sans';
    font-size: 14px;
    align-items: left;
    color: #75787B;
    max-width: 220px;
    margin-right: 10px;
}

.capabilityBreadcrumbItem::after {
    content: "/";
    padding-left: 10px;
}

.capabilityBreadcrumbItemActive {
    font-family: 'Open Sans';
    font-size: 14px;
    align-items: left;
    color: #0D8390;
    text-decoration: underline;
    cursor: pointer;
}