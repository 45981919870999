.mainDiv {
    border: 4px solid red;
    color: rgb(201, 201, 201);
    font-size: 30px;
    font-weight: bold;
    background-color: transparent;
    z-index: 999;
    text-align: center;
    margin-top: 5rem;
    width: 95%;
    padding: 1rem;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -500%);
}