.title{
    padding-left: 11px;
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    margin-top: 16px;
    display: inline-block;
}

.icon{
    margin: 3px 0px;
}

.supportingMaterials{
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 32px;
    margin-left: 10px;
}