.storefront-back {
  height: auto;
  width: auto;
  padding: 1px;
}

.storefront-level-card {
  width: 94%;
  margin-left: 1%;
  padding: 0px 16px 19px 16px;
}

@mixin storefront-tab-common() {
  font-family: var(--font-family);
  font-style: var(--font-style);
  font-family: "OpenSans-Regular";
  font-weight: 700;
  font-size: 18px;
  line-height: 180%;
  cursor: pointer;
}

.storefront-tab-active {
  @include storefront-tab-common();
  font-family: "OpenSans-Bold";
  color: #000000;
  text-underline-offset: 14.4px;
  text-decoration: underline;
  text-decoration-color: #86bc25;
  text-decoration-thickness: 3px;
  z-index: 1;
}

.storefront-tab-inactive {
  @include storefront-tab-common();
  font-family: "OpenSans-Bold";
  color: #adaeb1;
}

.tabs-underline {
  margin-top: 7px;
  color: #b1a9a9;
}

.modal-layout-custom {
  position: relative;
  top: 22px !important;
  max-width: 40%;
  width: 40%;
  z-index: 1052;
  transition: none;
  opacity: 1;
}

@media screen and (max-width: 1200px) {
  .modal-layout-custom {
    max-width: 50%;
    width: 50%;
  }
}

.modal-header-title {
  font-family: var(--font-family);
  font-family: var(--font-family);
  font-family: "OpenSans-Regular";
  font-weight: 600;
  font-size: 18px;
  line-height: 24px;
  color: #292929;
}

.modal-header {
  padding: 10px 20px;
}
.storefront-tab-names-text-active {
  font-family: "OpenSans-Regular";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  color: #ffffff;
  padding: 10px;

  background: #86bc25;
}
.storefront-tab-names-text-inactive {
  font-family: "OpenSans-Regular";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  color: #000000;
  padding: 10px;
}

.storefrontViewScreenTabs_menu_tab {
  border: 1px solid #D0D0CE;
  background: #ffffff;
  cursor: pointer;
}

.storefront-menu-content {
  background: #ffffff;
  box-shadow: 0px 4px 12px rgba(0, 92, 92, 0.08);
  height: auto;
  margin-top: 20px;
}
.storefront-menu-content-card {
  padding-top: 30px;
  padding-left: 20px;
  padding-bottom: 15px;
}
.storefrontMenu-firstcard {
  font-family: "OpenSans-Bold";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 172%;
  color: #000000;
}
.storefrontMenu-firstcard-righttext {
  font-family: "OpenSans-Regular";
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 24px;
  margin-left: -4px;
  color: #000000;
  flex: none;
  order: 1;
  flex-grow: 0;
}
.storefrontMenu-firstcard-space {
  padding-bottom: 15px;
}
.adminreport-download-btn {
  display: flex;
  align-items: center;
  width: 140px;
  height: 43px;
  background: #00abab;
  cursor: pointer;
}

.storefront-download-option {
  padding: 10px 14px 10px 10px;
}

.storefront-download-option-text {
  font-family: "OpenSans-Bold";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  color: #ffffff;
}
.storefront-download-option:hover {
  border: none !important;
}

.form-check-input:checked {
  background-color: green !important;
  border-color: green !important;
}
.form-check-input:focus {
  box-shadow: none !important;
  border-color: #bfbfbf !important;
}
#flexSwitchCheckDefault {
  cursor: pointer;
}

.material-switch > input[type="checkbox"] {
  display: none;
}

.material-switch > label {
  cursor: pointer;
  height: 0px;
  position: relative;
  width: 40px;
}
.first-card-image-padding {
  padding-left: 0px !important;
  margin-left: -7px;
}
.material-switch > label::before {
  background: rgb(0, 0, 0);
  box-shadow: inset 0px 0px 10px rgba(0, 0, 0, 0.5);
  border-radius: 8px;
  content: "";
  height: 12px;
  margin-top: -9px;
  position: absolute;
  opacity: 0.3;
  transition: all 0.4s ease-in-out;
  width: 28px;
}
.material-switch > label::after {
  background: #f5f5f5;
  border-radius: 16px;
  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.08);
  content: "";
  height: 16px;
  left: -4px;
  margin-top: -8px;
  position: absolute;
  top: -3px;
  transition: all 0.3s ease-in-out;
  width: 16px;
}
.material-switch > input[type="checkbox"]:checked + label::before {
  background: #ddefe8;
  opacity: 0.5;
}
.material-switch > input[type="checkbox"]:checked + label::after {
  background: #00abab;
  left: 17px;
}

.empty-capabilities {
  margin-top: 100px !important;
  margin-bottom: 100px !important;
}

.no-vc-heading {
  font-family: var(--font-family);
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  color: #75787b;
  font-family: "OpenSans-Regular";
}

.no-vc-description {
  font-style: var(--font-style);
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  // text-align: center;
  color: #53565a;
  opacity: 0.5;
}
.separator-storefront-tab {
  color: #d0d0ce;
  opacity: 0.5;
  position: absolute;
  padding-left: 6px;
}

.value-chain-wrapper{
  // min-height: 400px;
  display: flex;
  transition-property: margin-left;
  transition-duration: 0.7s;
}
.adminreport-download_pdf_btn {
  height: 42px !important;
}
.storefront-section-tabinline:last-child .separator-storefront-tab {
  display:none !important;
  }
  .storefrontViewScreenTabs_menu_tabs_flex_download {
    width:15% !important;
  }
  .storefrontViewScreenTabs_menu_tabs_flex {
    width:85% !important;
  }
  .storefrontViewScreenTabs_menu_tab .storefront-section-tabinline:nth-child(1){
    width:19%;
    text-align: center;
  }
  .storefrontViewScreenTabs_menu_tab .storefront-section-tabinline:nth-child(2){
    width:13%;
    text-align: center;
  }
  .storefrontViewScreenTabs_menu_tab .storefront-section-tabinline:nth-child(3){
    width:19%;
    text-align: center;
  }
  .storefrontViewScreenTabs_menu_tab .storefront-section-tabinline:nth-child(4){
    width:16%;
    text-align: center;
  }
  .storefrontViewScreenTabs_menu_tab .storefront-section-tabinline:nth-child(5){
    width:19%;
    text-align: center;
  }
  .storefrontViewScreenTabs_menu_tab .storefront-section-tabinline:nth-child(6){
    width:15%;
    text-align: center;
  }

  .tabs-spacing {
    padding: 10px 0px;
  }