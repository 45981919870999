.bannerBackground {
  height: 320px;
  background: black;
  position: relative;
}

.backgroundImage{
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 320px;
}

.bannerContent {
  padding: 0px 40px 80px 40px;
  margin-top: 120px;
}

.slash{
  padding: 0px 8px;
}

@media screen and (max-width: 1423px) {
  .bannerContent {
    padding: 0px 25px 0px 40px;
  }
}

.bannerTitle {
  font-family: "OpenSans-Regular";
  font-style: normal;
  font-weight: 600;
  font-size: 40px;
  line-height: 36px;
  margin: 50px 0px ;
  color: #ffffff;
}

.breadcrumbTitle {
  font-family: "OpenSans-Regular";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: #fafafa;
  position: relative;
  padding-bottom: 29px;
  top: 3px;
}

.bannerFooter {
  font-family: "OpenSans-Regular";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: #fafafa;
  opacity: 0.7;
  bottom: 5px;

}

.bookmark{
  color:  white !important;
  height: 30px;
  margin-top: -14px;
  margin-left: 24px;
  cursor: pointer;
}

.filledBookmark{
  color: white   !important;
  height: 30px;
  margin-top: -14px;
  margin-left: 24px;
  cursor: pointer;
}

@media screen and (max-width: 1746px) {
  .bannerFooter {
    margin-top: 20px;
  }
}
