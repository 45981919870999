.sector-level-card {
  width: 94%;
  margin-left: 3%;
  padding: 19px 16px;
}

.select-industry-sector-back {
  background: #f3f3f3;
  height: auto;
  padding: 1px;
}

.banner-layout {
  margin-top: 45px;
  margin-bottom: 40px;
  margin-left: -1px;
  border-top: 0.1px solid #3e3d3d;
}

.sector-title {
  font-family: "OpenSans-Bold";
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 32px;
}

.d-flex-justify-center{
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50vh;
}
