//OPENSANS-REGULAR FONT
@font-face {
  font-family: OpenSans-Regular;
  src: url(../../assets/fonts/OpenSans/OpenSans-Regular.ttf) format("truetype");
}
//
.OpenSans-Regular {
  font-family: OpenSans-Regular;
}

//OPENSANS-BOLD FONT
@font-face {
  font-family: OpenSans-Bold;
  src: url(../../assets/fonts/OpenSans/OpenSans-Bold.ttf) format("truetype");
}
//
.OpenSans-Bold {
  font-family: OpenSans-Bold;
}

//OPENSANS-SEMIBOLD FONT
@font-face {
  font-family: OpenSans-Semibold;
  src: url(../../assets/fonts/OpenSans/OpenSans-SemiboldItalic.ttf)
    format("truetype");
}
//
.OpenSans-Semibold {
  font-family: OpenSans-Semibold;
}
//OPENSANS-LIGHT FONT
@font-face {
  font-family: OpenSans-Light;
  src: url(../../assets/fonts/OpenSans/OpenSans-Light.ttf) format("truetype");
}
//
.OpenSans-Light {
  font-family: OpenSans-Light;
}

:root {
  --color-primary-blue: #007cb0;
  --color-gradient-left: #007cb0;
  --color-gradient-right: #00a3e0;
  --color-nav-active: #00a3e0;
  --color-links: #00abab;
  --font-family: "OpenSans-Regular" !important;
  --font-style: normal;
  --color-homepage-menu-active: #86bc25;

  --color-teal: #0d8390;
  --color-teal-bg: #ddefe8;
  --color-teal-hover: #9dd4cf;
}

html {
  scroll-behavior: smooth;
}

body {
  // Overriding bootstrap width
  width: 101%;
  overflow-x: hidden;
  font-family: "OpenSans-Regular" !important;
}

.container-custom {
  min-height: 70vh;
  padding: 0px;
  width: 100%;
}

.ant-checkbox-checked .ant-checkbox-inner {
  background-color: rgb(13,131,144);
  border-color: rgb(13,131,144);
}

.ant-checkbox .ant-checkbox-inner {
  border-radius: 0px;
}
